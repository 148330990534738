import React, { FC } from 'react';

import { IInputGroup } from './interfaces';

import './styles.scss';

// input group
const InputGroup: FC<IInputGroup> = ({
  children
}) => {
  return (
    <div className="input-group">
      {children}
    </div>
  );
};

export default InputGroup;