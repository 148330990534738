import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ISelectItem } from './interfaces';

import './styles.scss';

// select item
const SelectItem: FC<ISelectItem> = ({
  item,
  onSelectOption,
  selectedOption,
  translate
}) => {
  const { t }: any = useTranslation();

  // render
  return (
    <button
      className="select-item-button"
      type="button"
      onClick={() => onSelectOption(item)}
      data-active={selectedOption?.value === item?.value}>
      <p className="text">{translate === false ? item?.label
        : t(`form.select.${item?.label.toLowerCase().replace(/ /g, '_')}`)}</p>
    </button>
  );
};

export default SelectItem;