import { FC } from 'react';

import Icon from '../../Icon';

import { IPaginationButton } from './interfaces';

import './styles.scss';

// pagination button
const PaginationButton: FC<IPaginationButton> = ({
  attrs,
  onClick
}) => {
  // render
  return (
    <button
      {...attrs}
      className={`btn pagination-button ${attrs?.className}`}
      onClick={onClick}>
      <Icon icon="icon-arrow-dg" />
    </button>
  );
};

export default PaginationButton;