import React, { FC } from 'react';

import Icon from '../../../Icon';

import { ICommunicationsItemData } from './interfaces';

import './styles.scss';

// communications item data
const CommunicationsItemData: FC<ICommunicationsItemData> = ({
  day,
  month,
  year,
  lang,
}) => {
  // render
  return (
    <div
      className="communications-item-data">
      <Icon
        className="icon-dash"
        icon="icon-text-dg" />

      <p className="communications-item-data--day-month">
        {
          lang === 'en' ? (
            <>
               <span className="month">{month}</span>
               <span className="day">{day}</span>
            </>
          ) : (
            <>
              <span className="day">{day}</span>
              <span className="month">{month}</span>
            </>
          )
        }
       
      </p>
      <p className="communications-item-data--year">{year}</p>
    </div>
  );
};

export default CommunicationsItemData;