import React, { FC, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '../../../Icon';

import UseClickOutSide from '../../../../uses/useClickOutSide';

import { ISearchInput } from './interfaces';

import './styles.scss';

// search input
const SearchInput: FC<ISearchInput> = ({
  onSearch,
  open,
  setOpen
}) => {
  const { t }: any = useTranslation();

  const input = useRef<any>(null);
  const element: any = useRef<any>(null);

  // click out
  UseClickOutSide(element, () => setOpen(false));

  // on enter
  const onKeyPress = useCallback((e: any) => {
    if (e.charCode === 13 || e.keyCode === 13) {
      setOpen(false);
      onSearch(input.current.value);
    }
  }, [ onSearch, setOpen ]);

  // use effect
  useEffect(() => {
    if (input.current instanceof Object && open === true) {
      input.current.focus();
    }
  }, [ open, setOpen ]);
  
  // render
  return (
    <div
      className="search-input"
      ref={element}>
      <input 
        className='input-search'
        type='text'
        placeholder={t('form.input.here')}
        onKeyDown={onKeyPress}
        ref={input} />

      <button
        className="btn btn-search"
        onClick={() => onSearch(input.current.value)}>
        <Icon icon="icon-search" />
      </button>
    </div>
  );
};

export default SearchInput;