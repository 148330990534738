import React, { FC } from 'react';

import Container from '../../../layout/Container';
import ListMapItem from './ListMapItem';

import { IListMapItem } from './ListMapItem/interfaces';
import { IListMap } from './interfaces';

import './styles.scss';

// list map
const ListMap: FC<IListMap> = ({
  value: {
    listitems
  }
}) => {
  // render
  return (
    <div
      className="list-map">
      <Container>
        {listitems && listitems.map((item: IListMapItem, index: number) => 
          <ListMapItem {...item} key={index} />)}
      </Container>
    </div>
  );
};

export default ListMap;