import { FC, useCallback } from 'react';

import Text from '../../Typography/Text';

import { IPaginationInfo } from './interfaces';

import './styles.scss';

const PaginationInfo: FC<IPaginationInfo> = ({ current, total }) => {
  // decimal
  const decimal = useCallback((page: number) => page < 10 ? `0${page}` : page, []);

  return (
    <div className="pagination-info">
      <Text attrs={{ className: 'current' }}>{decimal(current)}</Text>
      <Text attrs={{ className: '_' }}>/</Text>
      <Text attrs={{ className: 'total' }}>{decimal(total)}</Text>
    </div>
  );
};

export default PaginationInfo;