import React, { FC, useCallback } from 'react';
import { animateScroll as scroll } from 'react-scroll';

import Text from '../../../Typography/Text';

import { ITimelineControlsItem } from './interfaces';

import './styles.scss';

// time line controls item
const TimelineControlsItem: FC<ITimelineControlsItem> = ({
  active,
  decade,
  index,
  parent,
}) => {
  // move
  const onMoveMotherFuck = useCallback((e): any => {
    const viewY = index * window.innerHeight;
    const offSet = window.pageYOffset + parent.getBoundingClientRect().top;
    const pos = viewY + offSet;

    scroll.scrollTo(pos + 10);
  }, [ parent, index ]);

  // render
  return (
    <button
      className="btn time-line-controls-item"
      data-active={active}
      onClick={(e: any) => onMoveMotherFuck(e)}>
      <Text attrs={{ className: 'text' }}>{decade}</Text>
    </button>
  );
};

export default TimelineControlsItem;