import { FC } from 'react';

import { ITextBlock } from './interfaces';

import './styles.scss';

// text block
const TextBlock: FC<ITextBlock> = ({
  title,
  label,
  text,
  type,
  unit
}) => {
  return (
    <div
      className="text-block"
      data-type={type}>
      {title && <p className="title">{title}</p>}
      {text && <p className="text">{text}</p>}
      {label && <p className="label">{label}</p>}

      {unit && <p className="min">{unit}</p>}
    </div>
  );
};

export default TextBlock;