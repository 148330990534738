// search
const IconSearch = () => {
  return (<svg 
    viewBox="0 0 18 18.1"
    enableBackground="new 0 0 18 18.1">
    <path d="M17.6 17.7c.3-.3.4-.6.4-.9 0-.3-.1-.7-.4-.9l-3.4-3.4.3-.5c.8-1.3 1.3-2.8 1.3-4.2 0-4.2-3.6-7.7-7.9-7.7C3.6 0 0 3.5 0 7.7c0 4.2 3.6 7.7 7.9 7.7 1.3 0 2.7-.3 3.8-1l.5-.3 3.5 3.4c.5.7 1.4.7 1.9.2zm-9.7-4.5c-3 0-5.5-2.4-5.5-5.4 0-3 2.5-5.4 5.5-5.4s5.5 2.4 5.5 5.4c0 2.9-2.4 5.4-5.5 5.4z" fill="#e9533f"/>
    <defs>
      <filter id="a" filterUnits="userSpaceOnUse" x="0" y="0" width="18" height="18.1">
        <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/></filter>
    </defs>
    <mask maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18.1" id="b">
      <circle cx="8" cy="8" r="8" fill="#c4c4c4" filter="url(#a)"/>
    </mask>
    <g mask="url(#b)">
      <path d="M17.6 17.7c.3-.3.4-.6.4-.9 0-.3-.1-.7-.4-.9l-3.4-3.4.3-.5c.8-1.3 1.3-2.8 1.3-4.2 0-4.2-3.6-7.7-7.9-7.7C3.6 0 0 3.5 0 7.7c0 4.2 3.6 7.7 7.9 7.7 1.3 0 2.7-.3 3.8-1l.5-.3 3.5 3.4c.5.7 1.4.7 1.9.2zm-9.7-4.5c-3 0-5.5-2.4-5.5-5.4 0-3 2.5-5.4 5.5-5.4s5.5 2.4 5.5 5.4c0 2.9-2.4 5.4-5.5 5.4z" fill="#0056A1"/>
      </g>
  </svg>);
};

export default IconSearch;