import React, { FC } from 'react';

import CardBusinessIcon from '../CardBusinessIcon';
import CardBusinessInfo from '../CardBusinessInfo';
import ParallaxFixDiv from '../../Parallax';

import { ICardBusinessItem } from './interfaces';

import './styles.scss';

// card business item
const CardBusinessItem: FC<ICardBusinessItem> = ({
  button_label,
  button_label2,
  image,
  icon,
  external_link,
  external_link2,
  title
}) => {
  return (
    <div
      className="card-business-item">
      <img
        className="image"
        src={image?.original.src}
        alt={title} />

      <ParallaxFixDiv y={[ -5, 5 ]}>
        <div className="card-business--content">
          <CardBusinessIcon
            icon={icon}
            title={title} />

          <CardBusinessInfo
            items={[
              { label: button_label, link: external_link, external: false },
              { label: button_label2, link: external_link2, external: true }
            ]} />
        </div>
      </ParallaxFixDiv>
    </div>
  );
};

export default CardBusinessItem;