import React, { FC } from 'react';

import LangSelectMenuItem from './LangSelectMenuItem';

import { ILangSelectMenuItem } from './LangSelectMenuItem/interfaces';
import { ILangSelectMenu } from './interfaces';

import listsEn from './lists-en.json';
import listsEs from './lists-es.json';
import listsPt from './lists-pt.json';

import './styles.scss';

// lang select menu
const LangSelectMenu: FC<ILangSelectMenu> = ({
  lang = 'pt'
}) => {
  // render
  return (
    <div
      className="lang-select-menu">
      {lang === 'pt' && listsPt.map((item: ILangSelectMenuItem, index: number) =>
        <LangSelectMenuItem {...item} key={index} />)}

      {lang === 'es' && listsEs.map((item: ILangSelectMenuItem, index: number) =>
        <LangSelectMenuItem {...item} key={index} />)}

      {lang === 'en' && listsEn.map((item: ILangSelectMenuItem, index: number) =>
        <LangSelectMenuItem {...item} key={index} />)}
    </div>
  );
};

export default LangSelectMenu;