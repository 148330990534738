import { ILangItem } from "./interfaces";

// langs
export const langs: ILangItem[] = [
  {
    lang: 'pt',
    name: 'pt',
  }, {
    lang: 'en',
    name: 'en'
  }, {
    lang: 'es',
    name: 'es'
  }
];