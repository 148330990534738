import React, { FC, useCallback } from 'react';

import Container from '../../../layout/Container';

import { IBannerPillarsItemProps } from './interfaces';

import './styles.scss';

// banner pillars item
const BannerPillarsItem: FC<IBannerPillarsItemProps> = ({
  active,
  current,
  direction,
  index,
  item
}) => {
  // get last
  const getLast = useCallback(() =>
    (direction === 'next') ? (current - 1) === index : (current + 1) === index
  , [ current, direction, index ]);

  // render
  return (
    <div
      className="banner-pillars-item"
      data-active={active}
      data-last={getLast()}
      data-item="banner-pillars-item">
      <div
        className="banner-pillars-item--bg">
        <div className="banner-pillars-item--bg--image">
          <img
            className="image"
            src={item?.image_background?.original.src}
            alt={item?.title} />
        </div>
      </div>
      
      {item?.subtype !== 'tipo2' && <Container>
        <div className="banner-pillars-item--info">
          <p className="text title">{item?.title}</p>
          <p className="paragraph description">{item?.description}</p>
        </div>
      </Container>}
    </div>
  );
};

export default BannerPillarsItem;