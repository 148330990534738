import React, { FC, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import MetaTags from 'react-meta-tags';

import Loading from '../../components/Loading';
import MainRoutes from './MainRoutes';

import UseMetaTags from '../../uses/useMetaTags';

import { LangContext } from '../../providers/LangProvider';

import { IMain } from './interfaces';

// main
const Main: FC<IMain> = ({
  location
}) => {
  const { lang }: any = useContext(LangContext);
  const { getMetaContent }: any = UseMetaTags(lang?.lang);

  // use effect
  useEffect(() => {
    if (location)
      window.scroll(0, 0)
  }, [ location ]);

  // main
  return (
    <>
      <MetaTags>
        <meta name="description" content={getMetaContent()} />
      </MetaTags>

      <MainRoutes location={location} lang={lang} />

      <div id="search"></div>

      <Loading />
    </>
  );
};

export default withRouter(Main);