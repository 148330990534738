import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import Text from '../../../../components/Typography/Text';

import { IPageSearchResultItem } from './interfaces';

import './styles.scss';

// page search result item
const PageSearchResultItem: FC<IPageSearchResultItem> = ({
  lang,
  meta: {
    slug
  },
  title
}) => {
  // render
  return (
    <div
      className="page-search-result-item">
      <Link className="link" to={`/${lang?.lang}/${slug}`}>
        <Text attrs={{ className: 'title' }}>{title}</Text>
      </Link>
    </div>
  );
};

export default PageSearchResultItem;