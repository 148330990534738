import React, { FC } from 'react';

import Container from '../../layout/Container';
import Icon from '../Icon';
import LinkArrow from '../LinkArrow';
import ParallaxFixDiv from '../Parallax';

import { ICallToAction } from './interfaces';

import './styles.scss';

// call to action
const CallToAction: FC<ICallToAction> = ({
  value: {
    button_label,
    external_link,
    description,
    title
  }
}) => {
  return (
    <div
      className="call-of-action">
      <Container>
        <p className="main-title">{title}</p>
        <p className="title description">{description}</p>
        
        <LinkArrow
          attrs={{
            href: `${external_link}`,
            className: 'link',
            target: '_blank'
          }}>{button_label}</LinkArrow>

        <div className="icon-large">
          <ParallaxFixDiv y={[ -20, 20 ]}>
            <Icon className="icon-large--icon" icon="icon-arrow-dg" />
          </ParallaxFixDiv>
        </div>
      </Container>
    </div>
  );
};

export default CallToAction;