import React, { FC } from 'react';

import CardBusinessItemDiv from './CardBusinessItem';
import Container from '../../layout/Container';

import { ICardBusiness } from './interfaces';

import './styles.scss';

// card business
const CardBusiness: FC<ICardBusiness> = ({ 
  value: {
    listitems
  }
}) => {
  // render
  return (
    <div
      className="card-business">
      <Container>
        <div className="card-business--list">
          {listitems && listitems.map((item, index: number) => 
            <CardBusinessItemDiv {...item} key={index} />)}
        </div>
      </Container>
    </div>
  );
};

export default CardBusiness;