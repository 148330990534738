import React, { FC } from 'react';

import Container from '../../../layout/Container';
import ParallaxFixDiv from '../../Parallax';
import TextBlock from '../TextBlock';
import TitleBlock from '../TitleBlock';

import { IIndicatorWorldPeople } from './interfaces';

import './styles.scss';

// image
const img = `${process.env.PUBLIC_URL}/images/indicators-world.png`;

// indicator world people
const IndicatorWorldPeople: FC<IIndicatorWorldPeople> = ({
  value: {
    number1,
    tipo1,
    title1,
    number2,
    tipo2,
    title2,
    number3,
    tipo3,
    title3,
    title
  }
}) => {
  return (
    <div
      className="indicator-world-people">
      <Container>
        <div className="indicator-world-people--bg">
          <ParallaxFixDiv y={[ 0, 0 ]}>
            <img
              src={img}
              alt={title} />
          </ParallaxFixDiv>
        </div>

        <div className="indicator-world-people--content">
          <TextBlock 
            title={title1}
            text={number1}
            label={tipo1} />

          <TextBlock 
            title={title2}
            text={number2}
            label={tipo2} />

          <TextBlock 
            title={title3}
            text={number3}
            label={tipo3} />
        </div>

        <div className="indicator-world-people--title">
          <TitleBlock type={2} title={title} />
        </div>
      </Container>
    </div>
  );
};

export default IndicatorWorldPeople;