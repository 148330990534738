import React, { FC, useCallback } from 'react';
import { format, parse } from 'date-fns';
import { ptBR, es, enUS } from 'date-fns/locale';

import CommunicationsItem from '../CommunicationsItem';
import Pagination from '../../Pagination';

import { ICommunicationsContent } from './interfaces';

import './styles.scss';
import { ICommunicationsItem } from '../CommunicationsItem/interfaces';

// news content
const CommunicationsContent: FC<ICommunicationsContent> = ({
  data,
  lang,
  page,
  onNextPrev,
  perPage,
}) => {
  // get locale
  const getLocale = useCallback((): any => {
    switch (lang) {
      case 'es':
        return es;
      case 'en':
        return enUS;
      case 'pt':
        default:
          return ptBR
    }
  }, [ lang ]);

  // get date
  const getDate = useCallback((date: string): ICommunicationsItem['date'] => {
    const month = format(new Date(`${date}Z6:00:00`), 'MMM', { locale: getLocale() })
    const year = format(new Date(`${date}Z6:00:00`), 'yyyy', { locale: getLocale() })
    let day;
    if (lang === 'en') {
      day = format(new Date(`${date}Z6:00:00`), 'co', { locale: getLocale() })
    } else  {
      day = format(new Date(`${date}Z6:00:00`), 'dd', { locale: getLocale() })
    } 

    return {
      day,
      month,
      year,
    }
  }, [ lang ]);

  // render
  return (
    <div
      className="communications-content">
      <div
        className="communications-content--container">
        {data?.items && data?.items.map((item: any, index: number) =>
          <CommunicationsItem
            {...item}
            date={getDate(item?.date)}
            lang={lang}
            key={index} />)}
      </div>
      
      {data?.meta &&
        <Pagination
          current={page}
          total={Math.ceil(data?.meta.total_count / perPage)}
          setCurrent={onNextPrev} />}
    </div>
  );
};

export default CommunicationsContent;