import React, { FC, useCallback } from 'react';

import Icon from '../../Icon';
import { LinkBorderH } from '../../LinkBorder';
import ParallaxFixDiv from '../../Parallax';

import { IImageTextItem } from './interfaces';

import './styles.scss';

// image text item
const ImageTextItem: FC<IImageTextItem> = ({ 
  button_label,
  description,
  image: { large: { alt, src, height, width } },
  internal_link,
  external_link,
  title,
  type,
  lang
}) => {
  // factory link
  const factoryLink = useCallback((url: string) => {
    let src: string = url.replace(/.*\/\/[^/]*/, '');

    if (src[src.length-1] === '/') {
      src = src.slice(0, -1);
    }

    return `/${lang}${src}`;
  }, [ lang ]);

  // render
  return (
    <div
      className="image-text-item"
      data-type={type}>
      <div className="image-text-item--image">
        <div className="image-text-item--image--bg"></div>

        <img
          className="image"
          src={src}
          height={height}
          width={width}
          alt={alt} />

        {type === 1 && 
          <Icon
            className="image-text-item--image--icon"
            icon="icon-text-dg" />}
      </div>

      <Icon className="image-text-item--icon" icon="icon-text-dg" />

      <ParallaxFixDiv className="image-text-item--info" y={[20, -20]}>
        <h1 className="h1 title">{title}</h1>
        <p className="text description">{description}</p>

        {((internal_link && external_link) || external_link) && <LinkBorderH
          href={external_link}
          rel="no-noreferrer"
          target="_blank">
          <p className="text small min">{button_label}</p>
        </LinkBorderH>}

        {internal_link && !external_link && <LinkBorderH href={factoryLink(internal_link?.url)}>
          <p className="text small min">{button_label}</p>
        </LinkBorderH>}
      </ParallaxFixDiv>
    </div>
  );
};

export default ImageTextItem;