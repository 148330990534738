import React, { FC } from 'react';

import ProfileCard from '../ProfileCard';

import { IProfileList } from './interfaces';

import './styles.scss';

// profile list
const ProfileList: FC<IProfileList> = ({
  items
}) => {
  // render
  return (
    <div
      className="profile-list"
      data-center={items.length < 3}>
      {items && items.map((item: any, index: number) => 
        <ProfileCard {...item} key={index} />)}
    </div>
  );
};

export default ProfileList;