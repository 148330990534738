import React, { FC } from 'react';

import Icon from '../../../Icon';

import { IListCarrouselMultimediaArrow } from './interfaces';

import './styles.scss';

// list carrousel multimedia arrow
const ListCarrouselMultimediaArrow: FC<IListCarrouselMultimediaArrow> = ({
  currentSlide = 0,
  slideCount = 0,
  onClick,
  type
}) => {
  return (
    <button
      className="btn list-carrousel-multimedia-arrow"
      data-type={type}
      data-disabled={type === 'prev' ? currentSlide <= 0 : currentSlide >= (slideCount - 1)}
      onClick={onClick}>
      <Icon icon="icon-arrow-dg" />
    </button>
  );
};

export default ListCarrouselMultimediaArrow;