import React, { FC, useEffect, useState } from 'react';
import { useMeasure } from "react-use";

import Icon from '../../Icon';
import Text from '../../Typography/Text';

import { IAccordionItem } from './interfaces';

import './styles.scss';

// accordion item
const AccordionItem: FC<IAccordionItem> = ({
  children = false,
  body,
  title
}) => {
  const [ open, setOpen ] = useState<boolean>(false);
  const [ contentHeight, setContentHeight ]: any = useState<number>(0);

  const [ ref, { height } ]: any = useMeasure();

  // use effect
  useEffect(() => {
    setContentHeight(height);
    
    window.addEventListener("resize", setContentHeight(height));
    return window.removeEventListener("resize", setContentHeight(height));
  }, [ height ]);

  // render
  return (
    <div className="accordion-item">
      <div
        className="accordion-item--title"
        data-active={open}>
        <button
          className="btn"
          onClick={() => setOpen(!open)}>
          <span className="paragraph title">{title}</span>
          <Icon icon="icon-arrow-dg" />
        </button>
      </div>
      
      <div
        className="accordion-item--body"
        data-active={open}
        style={{ height: open ? contentHeight : 0 }}>
        <div
          className="accordion-item--body--content"
          ref={ref}>
            {!children ? (
              <Text type="danger" text={body} />
            ): body}
        </div>
      </div>
    </div>
  );
};

export default AccordionItem;