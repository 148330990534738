import React, { FC } from 'react';

import Container from '../../layout/Container';
import Icon from '../Icon';
import ImageSideBySideItem from './ImageSideBySideItem';

import { IImageSideBySide } from './interfaces';

import './styles.scss';

// image side by side
const ImageSideBySide: FC<IImageSideBySide> = ({
  value: { image1, image2 }
}) => {
  return (
    <div
      className="image-side-by-side">
      <Container>
        <ImageSideBySideItem
          {...image1}
          type={1} />
        
        <ImageSideBySideItem
          {...image2}
          type={2} />

        <Icon className="icon" icon="icon-text-dg" />
      </Container>
    </div>
  );
};

export default ImageSideBySide;