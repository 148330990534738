import React, { memo, FC } from 'react';

import { IListBusinessSliderItem } from './interfaces';

import './styles.scss';

// Webdoor item
const ListBusinessSliderItem: FC<IListBusinessSliderItem> = ({
  active,
  dir,
  drag,
  item,
  last,
  type
}) => {
  // img
  const img = (type === 2) ? item?.icon : item?.image_background;

  // render
  return (
    <div
      className="list-business-slider-item"
      {...drag()}
      data-current={active}
      data-last={last}
      data-type={type}
      data-dir={dir}>
        {img && <img
          src={img.original.src}
          alt={img.original.alt} />}
    </div>
  );
}

export default memo(ListBusinessSliderItem);