import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';

import Container from '../../../layout/Container';
import Icon from '../../Icon';
import Text from '../../Typography/Text';
import TimelineItemSlider from './TimelineItemSlider';

import { ITimelineItem } from './interfaces';

import './styles.scss';

// time line item
const TimelineItem: FC<ITimelineItem> = ({
  active,
  dir,
  index,
  last,
  listsubitems,
  image,
  decade
}) => {
  const { t }: any = useTranslation();

  // render
  return (
    <div
      className="time-line-item"
      data-init={index === 0}
      data-active={active}
      data-last={last}
      data-dir={active || last ? dir : ''}>
      <div className="time-line-item--bg">
        <div
          className="time-line-item--image"
          data-active={active}>
          <img src={image.original.src} alt="" />
        </div>
      </div>

      <div
        className="time-line-item--info"
        data-active={active}>
        <Container>
          <Text attrs={{ className: 'time-line-item--title' }}>
            <Icon icon="icon-text-dg" />
            <span className="label">{t('decade')}</span>
            <span className="date">{decade}</span>
          </Text>

          {listsubitems &&
            <TimelineItemSlider items={listsubitems} />}
        </Container>
      </div>
    </div>
  );
};

export default TimelineItem;