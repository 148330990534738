import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { ITabMenuItem } from './interfaces';

import './styles.scss';

// tab menu item
const TabMenuItem: FC<ITabMenuItem> = ({
  lang,
  title,
  link,
}) => {
  // render
  return (
    <div
      className="tab-menu-item">
      <NavLink
        activeClassName="active"
        to={`/${lang}${link}`}
        className="link">{title}
      </NavLink>
    </div>
  );
};

export default TabMenuItem;