import React, { useState } from 'react';
import CentralDocsContainer from './CentralDocsContainer';
import { TypeValue } from './CentralDocsContainer/interaces';

import CentralDocsForm from './CentralDocsForm';

import { ICentralDocs } from './interfaces';

import './styles.scss'

const CentralDocs: React.FC<ICentralDocs> = ({
  lang
}) => {
  const [company, setCompany] = useState<TypeValue | null>(null);
  const [period, setPeriod] = useState<TypeValue | null>(null);

  // render
  return (
    <div className="central-docs">
      <CentralDocsForm
        lang={lang}
        company={company}
        setCompany={setCompany}
        period={period}
        setPeriod={setPeriod}
      />

      {company?.value && period?.value && (
        <CentralDocsContainer
          company={company}
          period={period}
        />)}
    </div>
  );
};

export default CentralDocs;