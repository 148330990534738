import React, { FC } from 'react';

import { IWebdoorInternal } from './interfaces';

import './styles.scss';

// webdoor internal
const WebdoorInternal: FC<IWebdoorInternal> = ({
  image,
  title
}) => {
  return (
    <div
      className="webdoor-internal">
      {image &&
        <img
          className="image"
          src={image?.meta.download_url}
          alt={title} />}

      <h1 className="h1">{title}</h1>
    </div>
  );
};

export default WebdoorInternal;