import React, { FC, useCallback } from 'react';

import LinkIcon from '../../LinkIcon';

import { ICardBusinessInfo, ICardBusinessInfoItem } from './interfaces';

import './styles.scss';

// card business info
const CardBusinessInfo: FC<ICardBusinessInfo> = ({
  items
}) => {
  // type
  const type = useCallback((link: string, index: number, label?: string, external?: boolean) => {
    if (external === true) {
      return <a
        className="action"
        href={`mailto:${link}`}
        target="_blank"
        rel="noreferrer"
        key={index}><p className="text">{label}</p></a>
    }

    return <LinkIcon
      className="action"
      href={link}
      props={{ target: '_blank' }}
      icon="icon-open"
      key={index}>{label}</LinkIcon>
  }, []);

  // render
  return (
    <div
      className="card-business-info">
      {items && items
        .filter(({ link }: ICardBusinessInfoItem) => link !== '')
        .map(({ label, link, external }: ICardBusinessInfoItem, index: number) => 
          type(link, index, label, external))}
    </div>
  );
};

export default CardBusinessInfo;