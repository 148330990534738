import React, { FC } from 'react';

import Icon from '../../../Icon';

import Text from '../../../Typography/Text';

import { ISearchFormTop } from './interfaces';

import './styles.scss';

// search form top
const SearchFormTop: FC<ISearchFormTop> = ({ setOpen }) => {
  return (
    <div
      className="search-form-top">
      <Text attrs={{ className: 'search-form-top--title' }}>Buscar</Text>

      <button
        className="btn search-form-top--btn-close"
        onClick={() => setOpen(false)}>
        <Icon icon="icon-times" />
      </button>
    </div>
  );
};

export default SearchFormTop;