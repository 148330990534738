import React, { FC, useCallback, useState } from "react";
import axios from "axios";
import useSWR from "swr";

import Container from "../../layout/Container";
import MediaVideosContent from "./MediaVideosContent";

import UseNavigation from "../../uses/useNavigation";

import { IMediaVideos } from "./interfaces";

import "./styles.scss";

// fetcher
const fetcher = (url: string) => axios.get(url).then(({ data }: any) => data);

// react app base
const { REACT_APP_BASE_API, REACT_APP_PAGINATION_NEWS }: any = process.env;

// Media videos
const MediaVideos: FC<IMediaVideos> = ({ lang = "" }) => {
  const [page, setPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);

  const { getUrl } = UseNavigation();
  const url = getUrl(lang, REACT_APP_BASE_API);

  // query
  const query: string = "?type=imprensa.Video&fields=date,youtube_url&offset=";

  // data
  const { data } = useSWR(
    `${url}/api/v2/pages/${query}${offset}&limit=${REACT_APP_PAGINATION_NEWS}&order=-date`,
    fetcher
  );

  // on next prev
  const onNextPrev = useCallback(
    (page: number) => {
      const offset = (page - 1) * REACT_APP_PAGINATION_NEWS;

      setOffset(offset);
      setPage(page);
    },
    [setPage, setOffset]
  );

  // render
  return (
    <div className="media-videos" data-loading={!data}>
      <Container>
        <MediaVideosContent
          data={data}
          page={page}
          lang={lang}
          onNextPrev={onNextPrev}
          perPage={REACT_APP_PAGINATION_NEWS}
        />
      </Container>

      <div className="media-videos--loader" data-type="loader">
        <div className="loader"></div>
      </div>
    </div>
  );
};

export default MediaVideos;
