import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '../../Icon'
import Text from '../../Typography/Text';

import { IWebdoorNext } from './interfaces';

import './styles.scss';

// webdoor next
const WebdoorNext: FC<IWebdoorNext> = ({ onClick }) => {
  const { t }: any = useTranslation();

  // render
  return (
    <button
      className="btn webdoor-next"
      onClick={onClick}>
      <Text attrs={{ className: 'text strong' }}>{t('next')}</Text>
      <Icon icon="icon-arrow-dg" />
    </button>
  );
};

export default WebdoorNext;