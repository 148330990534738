import React, { FC } from 'react';

import Container from '../../layout/Container';
import ImageTextItem from './ImageTextItem';

import { IImageText } from './interfaces';

import './styles.scss';

// image text
const ImageText: FC<IImageText> = ({
  value: {
    listitems
  },
  lang
}) => {
  // render
  return (
    <div
      className="image-text">
      <Container>
        {listitems && listitems.map((item, index: number) => 
          <ImageTextItem
            {...item}
            lang={lang}
            type={index % 2 === 0 ? 1 : 2}
            key={index} />)}
      </Container>
    </div>
  );
};

export default ImageText;