import React, { FC } from 'react';

import { IIndicatorNumberBlock } from './interfaces';

import './styles.scss';

// indicator number block
const IndicatorNumberBlock: FC<IIndicatorNumberBlock> = ({
  first,
  last
}) => {
  // render
  return (
    <div
      className="indicator-number-block">
      {first && 
        <div className="indicator-number-block--item">
          <p className="text">{first?.text}</p>
          <p className="label">{first?.label}</p>
        </div>}
      
      {last &&
        <div className="indicator-number-block--item">
          <p className="text">{last?.text}</p>
          <p className="label">{last?.label}</p>
        </div>}
    </div>
  );
};

export default IndicatorNumberBlock;