import React, { FC } from 'react';

import Container from '../../layout/Container';
import ReportsItem from './ReportsItem';

import { IReports } from './interfaces';

import './styles.scss';

// reports
const Reports: FC<IReports> = ({
  value: { 
    listitems
  }
}) => {
  // render
  return (
    <div
      className="reports">
      <Container>
        {listitems && listitems.map((item: any, index: number) =>
          <ReportsItem {...item} key={index} />)}
      </Container>
    </div>
  );
};

export default Reports;