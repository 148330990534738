// icon bio
const IconBio = () => {
  return (
    <svg
      enableBackground="new 0 0 39 43"
      viewBox="0 0 39 43"
      xmlSpace="preserve">
      <path fill="#ffffff" d="m29.1 18.6v-8c0-0.2-0.1-0.4-0.3-0.6l-9-9.4c-0.2-0.2-0.4-0.3-0.6-0.3h-14.2c-2.6 0-4.7 2.1-4.7 4.7v26.7c0 2.6 2.1 4.7 4.7 4.7h11.2c2.1 3.5 6 5.9 10.4 5.9 6.7 0 12.1-5.4 12.1-12 0-5.7-4.2-10.6-9.6-11.7zm-9-15.1l6 6.3h-3.9c-1.2 0-2.1-1-2.1-2.1v-4.2zm-15.1 31.3c-1.6 0-3-1.3-3-2.9v-26.8c0-1.6 1.3-3 3-3h13.3v5.5c0 2.1 1.7 3.9 3.9 3.9h5.1v6.8h-0.7c-3 0-5.8 1.1-8 3h-11.2c-0.5 0-0.9 0.4-0.9 0.9s0.4 0.9 0.9 0.9h9.6c-0.6 0.8-1.2 1.7-1.6 2.7h-8c-0.5 0-0.9 0.4-0.9 0.9s0.4 0.9 0.9 0.9h7.4c-0.2 0.9-0.3 1.9-0.3 2.8 0 1.6 0.3 3.1 0.8 4.4h-10.3zm21.6 5.8c-5.7 0-10.3-4.6-10.3-10.3s4.6-10.3 10.3-10.3 10.3 4.6 10.3 10.3-4.6 10.3-10.3 10.3z"/>
      <path fill="#ffffff" d="m5 34.8c-1.6 0-3-1.3-3-2.9v-26.8c0-1.6 1.3-3 3-3h13.3v5.5c0 2.1 1.7 3.9 3.9 3.9h5.1v6.8h-0.7c-3 0-5.8 1.1-8 3h-11.2c-0.5 0-0.9 0.4-0.9 0.9s0.4 0.9 0.9 0.9h9.6c-0.6 0.8-1.2 1.7-1.6 2.7h-8c-0.5 0-0.9 0.4-0.9 0.9s0.4 0.9 0.9 0.9h7.4c-0.2 0.9-0.3 1.9-0.3 2.8 0 1.6 0.3 3.1 0.8 4.4h-10.3z"/>
      <path fill="#ffffff" d="M20.1,3.5l6,6.3h-3.9c-1.2,0-2.1-1-2.1-2.1V3.5z"/>
      <path fill="#ffffff" d="m26.6 40.6c-5.7 0-10.3-4.6-10.3-10.3s4.6-10.3 10.3-10.3 10.3 4.6 10.3 10.3-4.6 10.3-10.3 10.3z"/>
      <path fill="#0056a1" d="m7.4 18.7h8.9c0.5 0 0.9-0.4 0.9-0.9s-0.4-0.9-0.9-0.9h-8.9c-0.5 0-0.9 0.4-0.9 0.9s0.4 0.9 0.9 0.9z"/>
      <path fill="#0056a1" d="m29.1 18.6v-8c0-0.2-0.1-0.4-0.3-0.6l-9-9.4c-0.2-0.2-0.4-0.3-0.6-0.3h-14.2c-2.6 0-4.7 2.1-4.7 4.7v26.7c0 2.6 2.1 4.7 4.7 4.7h11.2c2.1 3.5 6 5.9 10.4 5.9 6.7 0 12.1-5.4 12.1-12 0-5.7-4.2-10.6-9.6-11.7zm-9-15.1l6 6.3h-3.9c-1.2 0-2.1-1-2.1-2.1v-4.2zm-15.1 31.3c-1.6 0-3-1.3-3-2.9v-26.8c0-1.6 1.3-3 3-3h13.3v5.5c0 2.1 1.7 3.9 3.9 3.9h5.1v6.8h-0.7c-3 0-5.8 1.1-8 3h-11.2c-0.5 0-0.9 0.4-0.9 0.9s0.4 0.9 0.9 0.9h9.6c-0.6 0.8-1.2 1.7-1.6 2.7h-8c-0.5 0-0.9 0.4-0.9 0.9s0.4 0.9 0.9 0.9h7.4c-0.2 0.9-0.3 1.9-0.3 2.8 0 1.6 0.3 3.1 0.8 4.4h-10.3zm21.6 5.8c-5.7 0-10.3-4.6-10.3-10.3s4.6-10.3 10.3-10.3 10.3 4.6 10.3 10.3-4.6 10.3-10.3 10.3z"/>
      <path fill="none" d="m27 26v10" stroke="#E9533F" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path fill="none" d="M32,31H22" stroke="#E9533F" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
};

export default IconBio;