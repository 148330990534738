import React, { FC } from 'react';

import WebdoorSubItem from './WebdoorSubItem';

import { IWebdoorSub } from './interfaces';

import './styles.scss';

// Webdoor content
const WebdoorSub: FC<IWebdoorSub> = ({
  current,
  dir,
  items,
  last
}) => {
  // render
  return (
    <div
      className="webdoor-sub">
      {items && items.map((item: any, index: number) => (
        <WebdoorSubItem
          current={current === index}
          dir={dir}
          last={last === index}
          item={item}
          key={index} />))}
    </div>
  )
};

export default WebdoorSub;
