import React, { FC } from 'react';

import { IInputFormLabel } from './interfaces';

import '../styles.scss';

// input form label
const InputFormLabel: FC<IInputFormLabel> = ({
  children
}) => {
  return (
    <div className="input-form">
      {children}
    </div>
  );
};

export default InputFormLabel;