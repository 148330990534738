import React, { FC } from 'react';

import Container from '../../layout/Container';
import ProfileList from './ProfileList';

import { IProfile } from './interfaces';

import './styles.scss';

// profile
const Profile: FC<IProfile> = ({
  value: {
    listitems,
    title
  }
}) => {
  // render
  return (
    <div
      className="profile">
      <Container>
        {title &&
          <div className="profile--title">
            <h3 className="h3 add-line">{title}</h3>
          </div>}

        <ProfileList items={listitems} />
      </Container>
    </div>
  );
};

export default Profile;