import React, { FC, useState } from 'react';
import ReactPlayer from 'react-player';

import Container from '../../layout/Container';
import Icon from '../Icon';

import { IVideo } from './interfaces';

import './styles.scss';

// video
const Video: FC<IVideo> = ({
  value: {
    image,
    url
  }
}) => {
  const [ pause, setPause ] = useState<boolean>(false);

  // render
  return (
    <div
      className="video"
      data-active={pause}>
      <Container>
        <div className="video--container">
          <button
            className="btn video--image"
            onClick={() => setPause(!pause)}>
            <Icon icon="icon-video" />

            <img
              className="video--image--img"
              src={image?.large.src}
              alt="" />
          </button>

          <ReactPlayer
            className="video--video"
            height={480}
            width={854}
            playing={pause}
            url={url}
            onEnded={() => setPause(false)} />
        </div>
      </Container>
    </div>
  );
};

export default Video;