import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import ListBusinessControls from '../ListBusinessControls';

import { IListBusinessInfo, IListBusinessInfoItem } from './interfaces';

import './styles.scss';

// list business info
const ListBusinessInfo: FC<IListBusinessInfo> = ({
  current,
  count,
  items,
  onPrevNext,
  title
}) => {
  const [ height, setHeight ] = useState<number>(0);
  const element: any = useRef(null);

  // height body
  const heightBody = useCallback((element: HTMLElement) => {
    if (element instanceof Object) {
      const items = element.querySelectorAll('div[data-active]');

      items.forEach((item: any) => {
        const props = item.getBoundingClientRect();
        const value = props.height + 160;
        
        if (value > height) {
          setHeight(value);
        }
      });
    }
  }, [ height ]);

  // use effect
  useEffect(() => {
    if (element.current instanceof Object) {
      heightBody(element.current);
    }
  }, [ items, element, heightBody ]);

  // render
  return (
    <div
      className="list-business-info"
      ref={element}
      style={{ height }}>
      {items && items.map((item: IListBusinessInfoItem, index: number) =>
        <div
          className="list-business-info--item"
          data-active={index === current}
          key={index}>
          <p className="paragraph title">{title}</p>
          <h1 className="h1 subtitle">{item?.title}</h1>
          <p className="paragraph description">{item?.description}</p>
        </div>)}

      <ListBusinessControls
          current={current}
          count={count}
          onPrevNext={onPrevNext} />
    </div>
  );
};

export default ListBusinessInfo;