import React, { FC } from 'react';

import Text from '../../Typography/Text';

import { ICardBusinessIcon } from './interfaces';

import './styles.scss';

// card business icon
const CardBusinessIcon: FC<ICardBusinessIcon> = ({
  icon,
  title
}) => {
  return (
    <div
      className="card-business-icon">
      <span className="icon">
        <img
          height={icon?.original.height}
          width={icon?.original.width}
          src={icon?.original.src}
          alt={icon?.original.alt} />
      </span>
      <span className="text">
        <Text attrs={{ className: 'name' }}>{title}</Text>
      </span>
    </div>
  );
};

export default CardBusinessIcon;