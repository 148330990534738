import React, { FC } from 'react';

import Icon from '../../../../Icon';

import { IListBusinessControlsButton } from './interfaces';

import './styles.scss';

// list business controls button
const ListBusinessControlsButton: FC<IListBusinessControlsButton> = ({
  disabled,
  onClick,
  type
}) => {
  return (
    <button
      className="btn list-business-controls-button"
      data-disabled={disabled}
      data-type={type}
      onClick={() => onClick(type)}>
      <Icon icon="icon-arrow-dg" />
    </button>
  );
};

export default ListBusinessControlsButton;