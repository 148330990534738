import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Text from '../../../components/Typography/Text';

import { IFooterTerms } from './interfaces';

import './styles.scss';

// footer terms
const FooterTerms: FC<IFooterTerms> = ({ 
  items
}) => {
  const { t }: any = useTranslation();

  // render
  return (
    <div
      className="footer-terms">
      {Array.isArray(items) && items.map(({ name, url }, index: number) =>
        <li className="footer-terms--item" key={index}>
          <Link className="link" to={t(url)}>
            <Text attrs={{ className: 'strong min' }}>{t(name)}</Text>
          </Link>
        </li>)}
    </div>
  );
};

export default FooterTerms;