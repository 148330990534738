import axios from 'axios';
import React, { FC, useCallback, useState } from 'react';
import useSWR from 'swr';

import PageSearchResultPagination from './PageSearchResultPagination';
import Container from '../../../layout/Container';

import UseNavigation from '../../../uses/useNavigation';

import { IPageSearchResult } from './interfaces';

import './styles.scss';

// env
const { REACT_APP_BASE_API, REACT_APP_PAGINATION_NEWS }: any = process.env;

// fetcher
const fetcher = (url: string) => axios.get(url).then(({ data }: any) => data);

// page search result
const PageSearchResult: FC<IPageSearchResult> = ({
  lang,
  value
}) => {
  const [ page, setPage ] = useState<number>(1);
  const [ offset, setOffset ] = useState<number>(0);
  
  const { getUrl } = UseNavigation();
  const url = getUrl(lang?.lang, REACT_APP_BASE_API);
  
  // data
  const { data } = useSWR(
    `${url}/api/v2/pages/?search=${value}&offset=${offset}&limit=${REACT_APP_PAGINATION_NEWS}`, fetcher);

  // on next prev
  const onNextPrev = useCallback((page: number) => {
    const offset = (page - 1) * REACT_APP_PAGINATION_NEWS;

    setOffset(offset);
    setPage(page);
  }, [ setPage, setOffset]);
  
  // render
  return (
    <div
      className="page-search-result">
      <Container type={2}>
        <PageSearchResultPagination
          data={data}
          page={page}
          lang={lang}
          onNextPrev={onNextPrev}
          perPage={REACT_APP_PAGINATION_NEWS} />
      </Container>
    </div>
  );
};

export default PageSearchResult;