import React, { FC } from 'react';

import { IIndicatorNumberPeopleListItem } from './interfaces';

import './styles.scss';

// indicator number people list item
const IndicatorNumberPeopleListItem: FC<IIndicatorNumberPeopleListItem> = ({
  label_percent,
  percent
}) => {
  // render
  return (
    <div
      className="indicator-number-people-list-item">
      <p className="percent">{percent}</p>
      <p className="label">{label_percent}</p>
    </div>
  );
};

export default IndicatorNumberPeopleListItem;