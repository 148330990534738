import React, { FC, useCallback } from "react";
import Container from "../../layout/Container";
import useWindowSize from "../../uses/useWindowSize";
import { ITable } from "./interfaces";

import "./styles.scss";

// table
const Table: FC<ITable> = ({
  value: { data, first_row_is_table_header, first_col_is_header },
}) => {
  const { width } = useWindowSize();

  // get table cols head
  const getTableColsRows = useCallback((data: any[], type: number = 1) => {
    if (!data.length) return false;

    const head: any[] = [];

    for (let i = 0; i < data.length; i++) {
      const value = data[i];

      if (type === 1) {
        head.push(<th key={i}>{value}</th>);
      } else {
        if (i === 0) {
          head.push(<th key={i}>{value}</th>);
        } else {
          head.push(<td key={i}>{value}</td>);
        }
      }
    }

    return <tr>{head}</tr>;
  }, []);

  //get table responsive
  const getTableColsResponsive = (data: any[], type: number = 1) => {
    if (!data.length) return false;

    const head = data[0];

    const body = data
      .map((value: any, index: number) => {
        if (index === 0) return false;
        return value;
      })
      .filter(Boolean);

    const html = [];
    for (let i = 0; i < body.length; i++) {
      const value = body[i];

      for (let x = 0; x < value.length; x++) {
        html.push(
          <div key={x} className="containerMobile">
            <h3 className="title">{head[x]}</h3>
            <p className="text">{value[x]}</p>
          </div>
        );
      }
    }

    return <>{html}</>;
  };

  // get table cols
  const getTableCols = useCallback(
    (data: any[], notBody: boolean) => {
      if (!data.length) return false;

      const head: any = getTableColsRows(data[0]);

      if (!notBody) {
        const newBody = data
          .map((value: any, index: number) => {
            if (index === 0) return false;
            return value;
          })
          .filter(Boolean);

        const body: any = getTableColsRows(newBody, 2);
        return (
          <>
            <thead>{head}</thead>
            <tbody>{body}</tbody>
          </>
        );
      }

      return (
        <>
          <thead>{head}</thead>
        </>
      );
    },
    [getTableColsRows]
  );

  // get table rows
  const getTableRows = useCallback((data: any[]) => {
    if (!data.length) return false;

    const newBody = data
      .map((value: any, index: number) => {
        if (index === 0) return false;
        return value;
      })
      .filter(Boolean);

    const table: any[] = [];

    for (let i = 0; i < newBody.length; i++) {
      const row = newBody[i];

      if (row) {
        const cells: any[] = [];

        for (let j = 0; j < row.length; j++) {
          if (j === 0) {
            cells.push(<th key={j}>{row[j]}</th>);
          } else {
            cells.push(<td key={j}>{row[j]}</td>);
          }
        }

        table.push(<tr key={i}>{cells}</tr>);
      }
    }

    return <tbody>{table}</tbody>;
  }, []);

  // render
  return (
    <div className="table">
      <Container type={2}>
        {width >= 768 ? (
          <table>
            {first_row_is_table_header === true &&
              getTableCols(
                data,
                first_col_is_header && first_row_is_table_header
              )}

            {first_col_is_header === true && getTableRows(data)}
          </table>
        ) : (
          <>
            {first_row_is_table_header === true && getTableColsResponsive(data)}
          </>
        )}
      </Container>
    </div>
  );
};

export default Table;
