// share
const Share = () => {
  return (
    <svg viewBox="0 0 23 27" enableBackground="new 0 0 23 27">
      <path className="st0" d="M20.3 22.3v-.4c-.1-.3-.2-.6-.5-.9-.8-.8-2.1-.8-3 0-.2.2-.3.4-.5.6-.1.2-.1.5-.1.7 0 1.2.9 2.1 2 2.1h.4c1.1-.2 1.7-1.1 1.7-2.1zM16.4 4.4c-.1.2-.1.5-.1.8 0 .9.4 1.6 1.1 2 .6.3 1.4.2 2.1-.2.3-.2.6-.5.7-.9.1-.2.1-.5.1-.8 0-1.2-.9-2.1-2-2.1-.4 0-.8.1-1.1.4-.3.2-.6.5-.8.8zM5.9 12s-.1 0 0 0c-.1-.1-.2-.2-.3-.2-.1 0-.1-.1-.2-.1-.6-.1-1.1-.1-1.6.2-.2.2-.5.4-.7.6-.2.2-.3.5-.4.7 0 .2-.1.3-.1.5v.4c.2.7.7 1.3 1.5 1.6.4.1.8.1 1.2 0 .4-.1.7-.4.9-.7v-.1c.2-.3.3-.7.3-1.1.3-.7-.1-1.4-.6-1.8z"/>
      <path className="st1" d="M20.3 5.3c0 .3-.1.5-.1.8.1-.1.1-.3.2-.4 0-.2 0-.3-.1-.4 0-1.2-.9-2.1-2-2.1 1.2 0 2 .9 2 2.1zM14 4.8v.5c0 .3 0 .6.1.9-.1-.4-.1-.9-.1-1.4zM8.8 12l6.4-3.6c.5.5 1.1.8 1.7 1-.4-.1-.7-.3-1-.5-.5-.3-.9-.7-1.3-1.2-.3-.5-.5-.9-.7-1.5v.2l-6.6 3.8c.8.5 1.2 1.1 1.5 1.8zM4.1 18.2c-.1 0-.2 0-.3-.1-.2 0-.4-.1-.6-.2.5.2 1 .3 1.5.3h-.6zM4 9.4c-1 .2-1.8.7-2.5 1.4l.4-.4c.7-.4 1.4-.8 2.1-1zM5.9 9.5c.2 0 .3.1.5.2-.2-.1-.3-.1-.5-.2zM18.7 18c.2 0 .3 0 .5.1-.2-.1-.3-.1-.5-.1zM17.6 9.6c-.1 0-.3-.1-.4-.1.2 0 .3.1.4.1zM14.9 2.6v-.1l.2-.2c.8-.9 1.9-1.4 3.1-1.4-2.1 0-3.9 1.6-4.3 3.7l.1-.1c.2-.8.5-1.4.9-1.9zM18.3 9.6h-.1.1zM20.1 9.3s-.1 0-.1.1c0-.1 0-.1.1-.1zM20.8 8.8l-.1.1.1-.1zM19.8 18.2c.1 0 .1 0 .2.1-.1 0-.1 0-.2-.1zM14.1 23.5c0-.2-.1-.4-.1-.6 0 .2.1.4.1.6zM16.8 26.5h-.1c-.2-.1-.3-.2-.5-.3.5.3 1 .5 1.6.6-.2 0-.3-.1-.5-.1-.2-.1-.4-.1-.5-.2zM5.7 11.9h-.1c.1 0 .2.1.3.1-.1 0-.2-.1-.2-.1zM18.3 26.8h-.1.1zM18.3 24.5h.2c-.1-.1-.1-.1-.2 0 0-.1 0 0 0 0zM20.3 22.3c0 1-.7 1.9-1.6 2.1.5-.1 1-.4 1.3-.9v-.1c.1-.1.1-.2.1-.3 0-.1.1-.2.1-.2v-.1c.1-.3.1-.7 0-1 .1.2.1.4.1.5zM14.1 21.7c0-.1 0-.2.1-.3v-.3l.1-.2c.2-.5.5-1 .8-1.4l.2-.3c.9-.8 1.9-1.2 3-1.2-1.1 0-2.2.4-3 1.2l-6.4-3.6c-.3.7-.8 1.3-1.4 1.7l6.7 3.8c-.2.5-.2.9-.2 1.3v-.5c0-.1 0-.2.1-.2zM19.2 26.7c0 .1 0 .1 0 0 0 .1 0 .1 0 0zM5 9.4c.2 0 .4 0 .6.1-.2-.1-.4-.1-.6-.1zM4.8 9.4c-.2 0-.5 0-.7.1.2-.1.5-.1.7-.1zM5.9 12c.5.4.9 1 .9 1.8 0 .4-.1.8-.3 1.1.1-.2.2-.3.3-.5.1-.2.1-.4.1-.6-.1-.7-.5-1.4-1-1.8z"/>
      <path className="st2" d="M17.2 9.5c-.1 0-.1 0-.2-.1.1.1.1.1.2.1zM18.3 9.7c.6 0 1.1-.1 1.6-.3-.4.1-1 .2-1.6.3 0-.1 0 0 0 0zM20.7 8.9l-.6.3c.2 0 .4-.2.6-.3zM18.2 9.6c-.2 0-.4 0-.6-.1.2.1.4.1.6.1zM18.3 7.5c.9 0 1.6-.6 1.9-1.4-.2.3-.4.6-.7.9-.7.5-1.5.5-2.1.2-.6-.4-1.1-1.1-1.1-2 0-.3.1-.5.1-.8.2-.3.4-.6.8-.9.4-.2.7-.4 1.1-.4h-.1c-1.2 0-2 .9-2 2.1 0 1.3 1 2.3 2.1 2.3zM14.1 6.3s0-.1 0 0c0-.1 0 0 0 0zM14 4.5v0z"/>
      <path className="st2" d="M22.7 5.2c0 1.5-.7 2.8-1.8 3.6l.3-.3c.8-.6 1.3-1.4 1.5-2.4.1-.7.1-1.5-.1-2.2-.2-.7-.5-1.3-1-1.8-.8-.9-2-1.4-3.2-1.4 1.2 0 2.4.5 3.2 1.3.7 1 1.1 2.1 1.1 3.2z"/>
      <path className="st2" d="M16 8.9c.3.2.7.4 1 .5.1 0 .1 0 .2.1.1 0 .3.1.4.1.2 0 .4 0 .6.1h.1c.6 0 1.1-.1 1.7-.3 0 0 .1 0 .1-.1l.6-.3.1-.1c1.1-.8 1.8-2.1 1.8-3.6 0-1.1-.4-2.2-1.1-3-.8-.9-2-1.3-3.2-1.3-1.2 0-2.4.5-3.1 1.4-.1-.1-.2 0-.2.1v.1c-.4.5-.7 1.1-.8 1.8l-.2.1v.3c0 .5 0 .9.1 1.4v.1c.1.5.3 1 .7 1.5.3.4.7.8 1.2 1.1zm2.3-5.8s0 .1 0 0c.1.1.1.1 0 0 1.2 0 2.1 1 2.1 2.2v.4c0 .1-.1.3-.2.4-.3.8-1 1.4-1.9 1.4-1.1 0-2-1-2-2.2-.1-1.3.8-2.2 2-2.2zM18.2 26.8h-.5.5zM14 22.9c0-.1 0-.1 0 0 0-.1 0-.1 0 0zM18.3 26.8c.3 0 .5 0 .8-.1-.2.1-.5.1-.8.1zM19.2 18c.2 0 .4.1.6.2-.2 0-.4-.1-.6-.2zM14.1 23.5c.2.7.5 1.4 1 2 .3.3.7.6 1.1.8-1.1-.7-1.8-1.7-2.1-2.8zM16.3 22.3c0-.3.1-.5.1-.7.1-.2.3-.4.5-.6.9-.8 2.2-.8 3 0 .2.3.4.5.5.9-.2-1-1-1.7-2-1.7-1.2 0-2.2.9-2.1 2.1 0 1.2.9 2.2 2 2.2-1.1-.1-2-1-2-2.2zM18.7 24.4h-.2.2z"/>
      <path className="st2" d="M20.1 18.4H20c1.5.7 2.7 2.3 2.7 4.1 0 2.1-1.5 3.9-3.5 4.3 1.3-.3 2.4-1.2 3-2.4 0-.1.1-.1.1-.2s0-.1.1-.2c.6-1.5.4-3.3-.8-4.6-.4-.5-.9-.8-1.5-1zM18.3 18h.4-.4z"/>
      <path className="st2" d="M22.7 22.4c0-1.8-1.1-3.4-2.7-4.1-.1 0-.1 0-.2-.1-.2-.1-.4-.2-.6-.2-.1 0-.3 0-.5-.1h-.4c-1.1 0-2.1.4-3 1.2l-.2.3c-.4.4-.7.9-.8 1.4l-.1.2v.3c0 .1 0 .2-.1.3v.2c0 .3-.1.6 0 .9v.1c0 .2.1.4.1.6.3 1.2 1.1 2.2 2 2.8.2.1.3.2.5.3h.1c.2.1.3.1.5.2.1 0 .3.1.5.1h.7c.3 0 .6 0 .8-.1 1.8-.4 3.4-2.2 3.4-4.3zm-2.4.5v.1c0 .1-.1.2-.1.2 0 .1-.1.2-.1.3v.1c-.3.5-.8.8-1.3.9h-.4c-1.1 0-2-1-2-2.2s.9-2.1 2-2.1c1 0 1.8.7 2 1.7 0 .3 0 .6-.1 1zM4.8 18.2s0 .1 0 0c.9 0 1.9-.3 2.6-.9-.7.6-1.7.9-2.6.9zM.5 13.8c0-1.1.4-2.2 1.1-3-.5.5-.9 1.1-1.1 1.8-.2.9-.2 1.9.2 2.8l.1.1c.3.7.7 1.4 1.4 1.8.3.2.7.4 1.1.5-1.7-.5-2.8-2.1-2.8-4zM7.5 10.3c-.3-.2-.6-.4-1-.5-.1 0-.1 0-.2-.1.4.1.8.3 1.2.6zM5.6 9.4c.1 0 .2.1.3.1-.1 0-.2 0-.3-.1zM4 9.4c.1 0 .1 0 0 0 .1 0 .1 0 0 0zM5.5 15.7c-.4.1-.8.1-1.2 0-.8-.2-1.4-.8-1.5-1.6v-.4c0-.2 0-.4.1-.5.1-.3.2-.5.4-.7.2-.3.4-.5.7-.6.5-.2 1-.3 1.6-.2.1 0 .1.1.2.1-.3-.1-.5-.2-.9-.2-1.2 0-2.1.9-2.1 2.1-.2 1.3.8 2.3 1.9 2.3.7 0 1.4-.4 1.7-1v.1c-.3.2-.6.5-.9.6zM4.8 9.4h0zM5.8 12s.1 0 0 0c.1 0 0 0 0 0z"/>
      <path className="st2" d="M9 12.7c0-.3-.1-.4-.2-.6-.3-.7-.8-1.3-1.3-1.8h-.1c-.3-.3-.7-.5-1-.6-.2-.1-.3-.1-.5-.2-.1 0-.2-.1-.3-.1H5h-.1c-.3 0-.5 0-.7.1-.9.1-1.6.5-2.2 1-.1.1-.3.2-.4.4-.7.8-1.1 1.8-1.1 3 0 1.9 1.2 3.5 2.8 4.1.2.1.4.1.6.2.1 0 .2 0 .3.1.2 0 .5.1.7.1.9 0 1.8-.3 2.6-.9.6-.5 1.1-1.1 1.4-1.7 0-.4.1-.6.2-.8.2-.8.1-1.6-.1-2.3zm-2.3 1.7c-.1.2-.1.3-.3.5-.4.6-1 1-1.7 1-1.1 0-2.1-1-2.1-2.2 0-1.2.9-2.1 2.1-2.1.3 0 .6.1.9.2h.1c.1 0 .1.1.1.1h.1c.5.4.9 1 .9 1.8 0 .3 0 .5-.1.7z"/>
    </svg>
  );
};

export default Share;