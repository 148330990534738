// use meta tags
function UseMetaTags(lang: string) {
  const getMetaContent = () => {
    switch (lang) {
      case 'es':
        return 'Somos Novonor, inspirados por el futuro. Un grupo empresarial de origen brasileño y actuación global, con más de 75 años al servicio de la sociedad en sectores esenciales.'
      case 'en':
        return 'We are Novonor, inspired by the future. A business group with Brazilian origin and global reach, serving society in essential sectors for more than 75 years.'
      default:
        return 'Somos a Novonor, inspirada no futuro. Grupo empresarial de origem brasileira e atuação global, com mais de 75 anos servindo à sociedade em setores essenciais.';
    }
  };

  // render
  return {
    getMetaContent
  }
}

export default UseMetaTags;