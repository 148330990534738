import { parse, format } from 'date-fns';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Text from '../../../components/Typography/Text';
import NetworkShare from '../../../components/NetworkShare';
import WebdoorInternal from '../../../components/WebdoorInternal';
import withPage from '../../../components/Page';

import Container from '../../../layout/Container';
import Footer from '../../../layout/Footer';
import Header from '../../../layout/Header';

import UseNavigation from '../../../uses/useNavigation';
import UseNews from '../../../uses/useNews';

import { IPageNew } from './interfaces';

import './styles.scss';

// vars
const { REACT_APP_BASE_API }: any = process.env;

// page new
const PageNew: FC<IPageNew> = ({
  page,
  lang
}) => {
  const { t }: any = useTranslation();
  const { getNewsPrefix, getUrl } = UseNavigation();

  const [ data, setData ] = useState<any>(null);
  const dataFormat = parse(format(new Date(page?.date), 'yyyy-MM-dd'), 'yyyy-MM-dd', new Date());

  const prefix: string = getNewsPrefix(lang?.lang);
  const url: string = getUrl(lang?.lang, REACT_APP_BASE_API);

  // get link hotfix gambi
  const getLinkHotfix = useCallback((lang: string, prefix: any) => {
    if (lang === 'en')
      return `/${lang}/media-center/${prefix}`;

    if (lang === 'es')
      return `/${lang}/centro-de-comunicacion/${prefix}`;

    return `/${lang}/centro-de-midia/${prefix}`;
  }, []);

  // use effect
  useEffect(() => {
    const page = async () => await UseNews(url, prefix)
      .then((data) => setData(data));

    page();
  }, [ url, prefix ]);

  // render
  return (
    <div
      className="page-new">
      <Header type={2} />
        
      {data?.page &&
        <WebdoorInternal
          image={data?.page.imagem_do_header}
          title={data?.page.title} />}
        
      <NetworkShare
        lang={lang?.lang} />

      {page?.meta &&
        <Container type={2}>
          <Text attrs={{ className: 'date' }}>
            {lang.lang !== 'en' ? format(dataFormat, 'dd/MM/yyyy') : format(dataFormat, 'MM/dd/yyyy')}
          </Text>
          <h3 className="h2">{page?.title}</h3>
          <Text attrs={{ className: 'body' }} type="danger" text={page?.body} />
        
          <Link
            className="link-return"
            to={getLinkHotfix(lang?.lang, prefix)}>{t('see_news')}</Link>
        </Container>}
        
      <Footer type={2} lang={lang} /> 
    </div>
  );
};

export default withPage(PageNew);