import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ILangSelectMenuItem, ILangSelectMenuItemArray } from './interfaces';

import './styles.scss';

// lang select menu item
const LangSelectMenuItem: FC<ILangSelectMenuItem> = ({
  items,
  title,
}) => {
  const { t } = useTranslation();

  // render
  return (
    <div
      className="lang-select-menu-item">
      <p className="lang-select-menu-item--title">{t(title)}</p>

      <ul className="lang-select-menu-item--list">
        {items && items.map((item: ILangSelectMenuItemArray, index: number) =>
          <li className="lang-select-menu-item--list--item" key={index}>
            <a
              className="lang-select-menu-item--list--item--link"
              href={`${t(item.href)}`}
              target="_blank"
              rel="noreferrer">{t(item.text)}</a>
          </li>)}
      </ul>
    </div>
  );
};

export default LangSelectMenuItem;