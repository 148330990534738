import React, { useCallback, FC, useState } from 'react';
import { withRouter } from 'react-router';

import Container from '../../Container';
import HeaderMenuItem from './HeaderMenuItem';

import { IHeaderItem } from '../interfaces';
import { IHeaderMenu } from './interfaces';

import './styles.scss';

// Header Menu
const HeaderMenu: FC<IHeaderMenu> = ({
  lang,
  min,
  type,
  routes,
  location
}) => {
  const [ openMobile, setOpenMobile ] = useState<any>(null);

  // submenu
  const submenu = useCallback((item: any) => 
    item.submenu instanceof Object && item.submenu.length > 0, []);

  // set open mobile toggle
  const setOpenMobileToggle = useCallback((value: any) => {
    setOpenMobile(openMobile !== value ? value : null);
  }, [ openMobile, setOpenMobile ]);

  // render
  return (
    <div
      className="header-menu"
      data-min={min}
      data-type={type}>
      <Container>
        {routes && 
          routes.filter((item: any) => item.navegacao === 'primaria')
            .map((item: IHeaderItem, index: number) =>
              <HeaderMenuItem
                current={openMobile}
                item={item}
                index={index}
                key={index}
                lang={lang}
                location={location}
                open={openMobile === index || openMobile === null}
                setOpen={setOpenMobileToggle}
                submenu={submenu(item)}
                dataKey={index} />)}
      </Container>
    </div>
  );
};

export default withRouter(HeaderMenu);