import React, { memo, FC, useCallback } from 'react';

import { IWebdoorItem } from './interfaces';

import './styles.scss';

// Webdoor item
const WebdoorItem: FC<IWebdoorItem> = ({
  active,
  dir,
  drag,
  item,
  factoryLink,
  last,
}) => {
  // on drag
  const onDrag = useCallback(() => {
    if (typeof drag === 'function') return drag();

    return {};
  }, [ drag ]);

  // render
  return (
    <div
      className="webdoor-item"
      {...onDrag()}
      data-active={active}
      data-last={last}
      data-dir={dir}>
        <div className="link">
          <img src={item?.image.original.src} alt="webdoor" />
        </div>
    </div>
  );
};

export default memo(WebdoorItem);