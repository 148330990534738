import React, { FC, useCallback } from 'react';

import CommunicationsItemData from './CommunicationsItemData';
import CommunicationsItemInfo from './CommunicationsItemInfo';

import { ICommunicationsItem } from './interfaces';

import './styles.scss';

// news item
const CommunicationsItem: FC<ICommunicationsItem> = ({
  date,
  lang,
  link_external,
  resumo,
  title,
}) => {
  // content
  const content = useCallback(() => {
    return <>
      <CommunicationsItemData
        day={date.day}
        month={date.month}
        year={date.year} 
        lang={lang}
      />

      <CommunicationsItemInfo
        title={title}
        summary={resumo} />
    </>;
  }, [ date, resumo, title, lang]);


  // render
  return (
    <div
      className="communications-item">
      {!link_external ? content() :
        <a href={link_external} target="_blank" rel="noreferrer">
          {content()}
        </a>}
    </div>
  );
};

export default CommunicationsItem;