import React, { FC } from 'react';
import {
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookShareButton,
  FacebookMessengerShareButton,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
  TelegramShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

// env
const { REACT_APP_FACEBOOK_APP_ID }: any = process.env;

// network share content
const NetworkShareContent: FC<any> = () => {
  const location = window.location.href;

  // render
  return (
    <>
      <FacebookShareButton
        quote="Novonor"
        url={location}>
        <FacebookIcon size={20} />
      </FacebookShareButton>

      <FacebookMessengerShareButton
        appId={REACT_APP_FACEBOOK_APP_ID}
        url={location}>
          <FacebookMessengerIcon size={20} />
      </FacebookMessengerShareButton>

      <TelegramShareButton
        url={location}>
        <TelegramIcon size={20} />
      </TelegramShareButton>

      <LinkedinShareButton
        url={location}>
        <LinkedinIcon size={20} />
      </LinkedinShareButton>

      <TwitterShareButton
        url={location}>
        <TwitterIcon size={20} />
      </TwitterShareButton>

      <WhatsappShareButton
        url={location}>
        <WhatsappIcon size={20} />
      </WhatsappShareButton>
    </>
  );
};

export default NetworkShareContent;