import { locales } from '../i18n';

import { IHeaderItem } from '../layout/Header/interfaces';

// env
const { REACT_APP_REPLACE, REACT_APP_BASE_DOMAIN }: any = process.env;

// use Navigation
function UseNavigation () {
  // get news prefix
  const getNewsPrefix = (lang: string) => lang === 'en' ? 'news' : 'noticias';

  // split url
  const splitUrl = (route: IHeaderItem) => {
    if (route.meta.slug === 'home') return ['/'];
    
    if (route.meta instanceof Object) {
      const lang = getLang(route.meta.detail_url);
      const re = lang === 'pt' 
        ? REACT_APP_REPLACE 
        : REACT_APP_REPLACE.replace(REACT_APP_BASE_DOMAIN, `${lang}-${REACT_APP_BASE_DOMAIN}`);

      const url = route.meta.html_url.substring(0, route.meta.html_url.length - 1);

      const arrayUrl = url.replace(re, '');
      const attributesUrl = arrayUrl.split('/');

      return attributesUrl.length > 0 ? attributesUrl : false;
    }

    return false;
  };

  // get join route
  const getJoinRoute = (route: any, slug: string) => {
    if (slug === '/') return '/';

    if (Array.isArray(route)) {
      let url = '';

      for (let keyUrl in route) {
        if (route[keyUrl] !== '') {
          url += `${route[keyUrl]}/`;
        }
      }
      
      return url.substring(0, url.length - 1)
    }
  };

  // order by
  const orderById = (a: any, b: any) => {
    try {
      const itemA = a?.title.toLowerCase();
      const itemB = b?.title.toLowerCase();
      
      if (itemA < itemB) return -1;
      if (itemA > itemB) return 1;
      
      return 0;
    } catch(e) {
      console.error(`Error order by submenu: ${e}`);
    }
  };

  // create submenu
  const createSubmenu = (route: any, routes: IHeaderItem[], routeSlugs: string[]) => {
    if (Array.isArray(routeSlugs)) {
      const main = routes.filter(route => route.meta.slug === routeSlugs[0])[0];
      
      if (main instanceof Object) {
        if (main.submenu instanceof Object) {
          if (main.submenu.indexOf(route) === -1) main.submenu.push(route);
        } else {
          main.submenu = [];
        }

        main.submenu.sort(orderById);
      }
    }

    route.meta.slug = getJoinRoute(splitUrl(route), route.meta.slug);

    return route;
  };

  // find route parent submenu
  const routeParentSubmenu = (route: IHeaderItem, routes: IHeaderItem[]) => {
    return routes.filter((parent) => {
      if (parent.submenu) {
        for (let k in parent.submenu) {
          const child = parent.submenu[k];

          if (child instanceof Object) {
            if (child === route) return parent;
          }
        }
      }

      return false;
    });
  }
  // get parent
  const getParent = (route: IHeaderItem, routes: any[]) => {
    for (let key in routes) {
      const xRoute = routes[key];

      if (xRoute instanceof Object) {
        const value = route.meta.slug.split('/')[0];
        const item = xRoute.meta.slug.split('/')[0];

        if (value === item) return xRoute;
      }
    }
  };

  // join routes
  const getRoutes = (routes: IHeaderItem[]) => {
    if (!routes) return false;

    const items: IHeaderItem[] = [];

    for (let key in routes) {
      const route: IHeaderItem = routes[key];
      const routeSlugs: any = splitUrl(routes[key]);

      items.push(createSubmenu(route, routes, routeSlugs));
    }

    return items;
  };

  // get menu main
  const getMenuMain = (routes: any[]) => {
    if (Array.isArray(routes)) {
      const items = [];
      const ignoreRoutes = [];

      for (let key in routes) {
        const item = routes[key];
  
        if (item instanceof Object && item.navegacao === 'primaria') {
          if (item.hasOwnProperty('submenu') && item.submenu.length > 0) {
            const submenu = [];

            for (let subKey in item.submenu) {
              const subItem = item.submenu[subKey];

              if (subItem.meta.slug.split('/').length <= 2) {
                if (subItem instanceof Object && subItem.id !== item.id) {
                  ignoreRoutes.push(subItem.id);
                  submenu.push(subItem);
                }
              }
            }

            item.submenu = submenu;
          }

          if (ignoreRoutes.indexOf(item.id) === -1) {
            if (item.meta.slug.split('/').length <= 2) {
              items.push(item);
            } 
          }
        }
      }
  
      return items;
    }

    return [];
  };

  // get url translation
  const getUrl = (lang: string, base: string) => {
    if (!lang || lang === '' || lang === 'pt') return base;

    return base.replace(REACT_APP_BASE_DOMAIN, `${lang}-${REACT_APP_BASE_DOMAIN}`);
  };

  // get id
  const getLang = (url: string) => {
    const locale = locales.filter((lang: any) => url.includes(`${lang}-`) ? lang : '');

    return locale.length > 0 ? locale[0] : 'pt';
  };

  // render
  return {
    getNewsPrefix,
    getRoutes,
    getMenuMain,
    getParent,
    getUrl,
    routeParentSubmenu,
    getLang,
  };
}

export default UseNavigation;