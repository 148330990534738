import React, { FC, useRef, useState } from 'react';
import ReactPlayer from 'react-player';

import Icon from '../../../Icon';
import UseClickOutSide from '../../../../uses/useClickOutSide';

import { IMediaVideosItemModal } from './interfaces';

import './styles.scss';

// media videos item modal
const MediaVideosItemModal: FC<IMediaVideosItemModal> = ({
  onDuration,
  toggle,
  url
}) => {
  const [ pause, setPause ] = useState<boolean>(false);

  // element
  const element: any = useRef<any>(null);

  // click out
  UseClickOutSide(element, () => toggle());

  // render
  return (
    <div
      className="media-videos-item-modal"
      ref={element}>
      <div className="media-videos-item-modal--container">
        <button className="btn-close" onClick={() => toggle()}>
          <Icon icon="icon-times" />
        </button>

        <ReactPlayer
          className="media-videos-item-modal--video"
          height={470}
          width={835}
          playing={pause}
          url={url}
          onDuration={onDuration}
          onEnded={() => setPause(false)} />

        <div className="loader"></div>
      </div>
    </div>
  );
};

export default MediaVideosItemModal;