import React, { FC, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { LangContext } from '../../../providers/LangProvider';

import { ILangSelectList } from './interfaces';

import { locales } from '../../../i18n';

import './styles.scss';

// lang select list
const LangSelectList: FC<ILangSelectList> = ({
  setOpen
}) => {
  const { t } = useTranslation();
  const { lang, setLang }: any = useContext(LangContext);

  // on handle
  const onHandle = useCallback((item: any) => {
    setLang(item);
    setOpen(false);
    window.location.reload();
  }, [ setOpen, setLang ]);

  // return
  return (
    <div
      className="lang-select-list">
      <p className="text lang-select-list--title">{t('languages')}</p>
      
      <div className="lang-select-list--container">
        {locales && locales.map((item: string, index: number) =>
          <button
            className={`btn lang-select-list--container--item ${lang?.name === item ? 'active' : ''}`}
            onClick={() => onHandle(item)} key={index}>{item}</button>)}
      </div>
    </div>
  );
};

export default LangSelectList;