import React, { FC, useCallback, useState } from "react";
import axios from "axios";
import useSWR from "swr";

import Container from "../../layout/Container";
import CommunicationsContent from "./CommunicationsContent";

import UseNavigation from "../../uses/useNavigation";

import { ICommunications } from "./interfaces";

import "./styles.scss";

// fetcher
const fetcher = (url: string) => axios.get(url).then(({ data }: any) => data);

// react app base
const { REACT_APP_BASE_API, REACT_APP_PAGINATION_NEWS }: any = process.env;

// communications
const Communications: FC<ICommunications> = ({ lang }) => {
  const [page, setPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);

  const { getUrl } = UseNavigation();
  const url = getUrl(lang, REACT_APP_BASE_API);
  // query
  const query: string =
    "?type=imprensa.Comunicado&fields=resumo,date,link_external&offset=";

  // data
  const { data } = useSWR(
    `${url}/api/v2/pages/${query}${offset}&limit=${REACT_APP_PAGINATION_NEWS}&order=-date`,
    fetcher
  );

  // on next prev
  const onNextPrev = useCallback(
    (page: number) => {
      const offset = (page - 1) * REACT_APP_PAGINATION_NEWS;

      setOffset(offset);
      setPage(page);
    },
    [setPage, setOffset]
  );

  // render
  return (
    <div className="communications">
      <Container>
        <CommunicationsContent
          data={data}
          page={page}
          lang={lang}
          onNextPrev={onNextPrev}
          perPage={REACT_APP_PAGINATION_NEWS}
        />
      </Container>
    </div>
  );
};

export default Communications;
