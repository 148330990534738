import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import Home from '../../../pages/Home';
import PageDefault from '../../../pages/PageDefault';
import PageNews from '../../../pages/PageNews';
import PageSearch from '../../../pages/PageSearch';

import { IMainRoutes } from './interfaces';

// main routes
const MainRoutes: FC<IMainRoutes> = ({
  lang,
  location
}) => {
  return (
    <Switch>
      <Route
        path={'/'}
        exact>
        <Home
          lang={lang} />
      </Route>

      <Route 
        path={`/:lang/search/:term?`}
        exact>
          <PageSearch
            lang={lang} />
      </Route>

      <Route
        path={[`/:lang/noticias`, `/:lang/news`]}
        exact>
        <PageNews
          lang={lang}
          location={location} />
      </Route>

      <Route
        path={`/:lang/:main?/:page?/:subpage?`}
        exact>
        <PageDefault
          lang={lang}
          location={location} />
      </Route>
    </Switch>
  );
};

export default MainRoutes;