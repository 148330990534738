import { FC, useCallback } from 'react';

import Container from '../../layout/Container';

import PaginationButton from './PaginationButton';
import PaginationInfo from './PaginationInfo';

import { IPagination } from './interfaces';

import './styles.scss';

// pagination
const Pagination: FC<IPagination> = ({ current, total, setCurrent }) => {
  // on change
  const onChange = useCallback((dir: string) => {
    if (dir === 'prev') {
      setCurrent((current - 1) < 1 ? 1 : (current - 1), total);
    } else {
      setCurrent((current + 1) >= total ? total : (current + 1), total);
    }
  }, [ current, total, setCurrent ]);

  // render
  return (
    <div
      className="pagination">
      <Container>
        <PaginationButton
          attrs={{
            className: `prev ${current === 1 ? `disabled` : ''}`
          }}
          onClick={() => onChange('prev')} />

        <PaginationInfo current={current} total={total} />

        <PaginationButton
          attrs={{
            className: `next ${current >= total ? `disabled` : ''}`
          }}
          onClick={() => onChange('next')} />
      </Container>
    </div>
  );
};

export default Pagination;