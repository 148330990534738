import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useStickyroll } from '@stickyroll/hooks';

import TimelineContainer from '../TimelineContainer';
import TimelineControls from '../TimelineControls';

import UseWindowSize from '../../../uses/useWindowSize';
import UseDimension from '../../../uses/useDimension';

import { ParallaxPageContext } from '../../../providers/ParallaxPage';

import { ITimelineSlider } from './interfaces';

import './styles.scss';

// max top menu
const maxTop = 148;

// time line
const TimelineSlider: FC<ITimelineSlider> = ({
  value: { listitems }
}) => {
  const { dir, setPosition }: any = useContext(ParallaxPageContext);

  const [ last, setLast ] = useState<any>(0);
  const [ top, setTop ] = useState<number>(maxTop);
  
  const element = useRef<any>(null);

  const size = UseWindowSize();
  const props = UseDimension(element);

  const [ wrapper, {
    height, 
    currentPage, 
    pageCount, 
    pageIndex, 
    progress
  }]: any = useStickyroll({
    pages: listitems.length,
  });

  // on end
  const onEnd = useCallback(() =>
    (progress * pageCount) >= pageCount, [ pageCount, progress ]);

  // get direction
  const getLast = useCallback(() => {
    if (currentPage >= last) {
      setLast(currentPage - 1);
    } else if (currentPage + 1 < pageCount) {
      setLast(currentPage + 1);
    }
  }, [ currentPage, last, setLast, pageCount ]);

  // set position
  const onScrollHandle = useCallback(() => {
    const position = window.pageYOffset;
    const height = window.innerHeight;
    const mobile = size.width <= 1024 ? 55 : maxTop;

    if (element.current) {
      const val = ((position / height) * mobile) + mobile;

      if (val < mobile) {
        setTop(val);
      } else {
        setTop(mobile);
      }
      
      setPosition(position, {
        ...props,
        currentPage,
        pageCount, 
        view: size,
      });
    }

    return true;
  }, [ setTop, size, setPosition, props, currentPage, pageCount ]);

  // use effect
  useEffect(() => {
    window.addEventListener('scroll', onScrollHandle, { passive: true });

    return () => {
      window.removeEventListener('scroll', onScrollHandle);
    };
  }, [ onScrollHandle ]);

  // render
  return (
    <div
      className="time-line-slider"
      ref={element}
      data-active={progress > 0}
      data-last={onEnd()}
      style={{ top: -top }}>
      <TimelineContainer
        current={currentPage - 1}
        dir={dir?.dir}
        height={height}
        getLast={getLast}
        last={last}
        items={listitems}
        pageIndex={pageIndex}
        wrapper={wrapper} />

      <TimelineControls
        active={progress > 0}
        items={listitems}
        parent={element.current}
        currentPage={currentPage}
        size={size} />
    </div>
  );
};

export default TimelineSlider;