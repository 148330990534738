import React, { FC, useCallback, useEffect, useRef } from 'react';

import Container from '../../layout/Container';
import Select from '../Form/Select';
import TabMenuItem from './TabMenuItem';
import withMediaQueries from '../MediaQueries';

import { ITabMenu } from './interfaces';
import { ITabMenuItem } from './TabMenuItem/interfaces';
import { useHistory, useLocation } from 'react-router';

import './styles.scss';

// tab menu
const TabMenu: FC<ITabMenu> = ({
  value: {
    listitems
  },
  lang,
  queries
}) => {
  let history = useHistory();
  let location = useLocation();

  const element = useRef<any>(null);

  // get option
  const getOption = useCallback((items: ITabMenuItem[]) => {
    const url = location.pathname.replace(`${lang}/`, '');
    const option = items.filter(
      ({ link }: ITabMenuItem) => link === url)[0];

    if (option) {
      return { label: option.title, value: option.link };
    }
  }, [ lang, location ]);

  // on handle
  const onHandle = useCallback((option: any) =>
    history.push(`/${lang}${option?.value}`), [ lang, history ]);

  // on redirect
  const onRedirect = useCallback(() => {
    const item = listitems[0];

    if (item instanceof Object) {
      history.push(`/${lang}${item?.link}`);
    }
  }, [ listitems, history, lang ]);

  // parse options
  const parseOptions = useCallback((items: ITabMenuItem[]) => {
    return items.map(({ title, link }: ITabMenuItem) => {
      return {
        label: title,
        value: link
      };
    });
  }, []);

  // select
  const selectItems = parseOptions(listitems);

  // use effect
  useEffect(() => {
    if (element.current instanceof Object) {
      if (!element.current.nextSibling) {
        onRedirect();
      }
    }
  }, [ element, onRedirect ]);

  // render
  return (
    <div
      className="tab-menu"
      ref={element}>
      <Container>
        {listitems &&
          (queries === 'xs' && selectItems.length > 0)
            ? <Select
                defaultText="" 
                handleChange={onHandle}
                options={selectItems}
                selectedOption={getOption(listitems)}
                translate={false} />
            : listitems.map((item: ITabMenuItem, index: number) =>
              <TabMenuItem
                {...item}
                lang={lang}
                key={index} />)}
      </Container>
    </div>
  );
};

export default withMediaQueries(TabMenu);