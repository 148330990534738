import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { ILinkBorder } from './interfactes';

import './styles.scss';

// link border
export const LinkBorder: FC<ILinkBorder> = ({
  children,
  to = '/'
}) => {
  return (
    <Link
      className="link-border"
      to={to}>
      {children}
    </Link>
  );
};

// link border
export const LinkBorderH: FC<ILinkBorder> = ({
  children,
  href,
  rel,
  target
}) => {
  return (
    <a
      className="link-border-h"
      href={href}
      rel={rel}
      target={target}>
      {children}
    </a>
  );
};
