import React, { FC } from 'react';

import ListBusinessControlsButton from './ListBusinessControlsButton';

import { IListBusinessControls } from './interfaces';

import './styles.scss';

// list business controls
const ListBusinessControls: FC<IListBusinessControls> = ({
  current,
  count,
  onPrevNext
}) => {
  return (
    <div
      className="list-business-controls ">
      <ListBusinessControlsButton
        disabled={current === 0}
        type="prev"
        onClick={onPrevNext} />

      <ListBusinessControlsButton
        disabled={current >= (count - 1)}
        type="next"
        onClick={onPrevNext} />
    </div>
  );
};

export default ListBusinessControls;