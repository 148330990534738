import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ISelectTitle } from './interfaces';

import './styles.scss';

// select title
const SelectTitle: FC<ISelectTitle> = ({
  active,
  defaultText,
  onToggle,
  selectedOption,
  translate
}) => {
  const { t }: any = useTranslation();

  // render
  return (
    <button
      className="select-title"
      data-active={active}
      type="button"
      onClick={() => onToggle(!active)}>
      <p className="select-title--title">
        {translate === false ?
          <span
            className="text"
            data-empty={selectedOption?.value ? true : false}>
              {selectedOption?.label || defaultText}
            </span>
          : <span
            className="text"
            data-empty={selectedOption?.value ? true : false}>
              {selectedOption?.label
                ? t(`form.select.${selectedOption?.label.toLowerCase().replace(/ /g, '_')}`)
                : t(`form.select.${defaultText.toLowerCase().replace(/ /g, '_')}`)}
          </span>}
        <span className="icon icon-arrow-right"></span>
      </p>
    </button>
  );
};

export default SelectTitle;