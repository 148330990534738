import React, { FC, useCallback, useContext, useEffect, useState } from 'react';

import HeaderMenuMobile from './HeaderMenuMobile';
import HeaderMenu from './HeaderMenu';
import HeaderTop from './HeaderTop';
import withMediaQueries from '../../components/MediaQueries';

import { NavContext } from '../../providers/NavProvider';
import { LangContext } from '../../providers/LangProvider';

import useNavigation from '../../uses/useNavigation';

import { IHeader } from './interfaces';
import { INavContext } from '../../providers/NavProvider/interfaces';

import './styles.scss';

// header
const Header: FC<IHeader> = ({
  type,
  queries
}) => {
  const { lang }: any = useContext(LangContext);
  const { routes }: INavContext = useContext(NavContext);

  const [ open, setOpen ]: any = useState<boolean>(false);
  const [ min, setMin ]: any = useState<boolean>(false);

  const { getMenuMain } = useNavigation();

  // header min
  const headerMin = useCallback(() => setMin(window.pageYOffset > 80), []);

  // use effect
  useEffect(() => {
    const watchScroll = () => { window.addEventListener("scroll", headerMin); };

    watchScroll();

    return () => {
      window.removeEventListener("scroll", headerMin);
    };
  }, [ headerMin ]);

  // render
  return (
    <header
      className="header"
      data-open={open}
      data-min={type === 3 || min}>
      
      {routes &&
        <>
          <HeaderTop
            lang={lang?.lang}
            min={min}
            queries={queries} 
            open={open}
            routes={routes}
            setOpen={setOpen} />

          {(queries === 'xlg') &&
            <HeaderMenu
              lang={lang?.lang}
              min={min}
              routes={getMenuMain(routes)}
              type={type} />}

          {(queries !== 'xlg') &&
            <HeaderMenuMobile
              open={open}
              lang={lang?.lang}
              routes={routes}
              mainMenu={getMenuMain(routes)}
              setOpen={setOpen} />}
        </>}
    </header>
  );
};

export default withMediaQueries(Header);