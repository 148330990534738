import React, { FC } from 'react';

import BannerPillarsItem from '../BannerPillarsItem';

import { IBannerPillarsItem } from '../BannerPillarsItem/interfaces';
import { IBannerPillarsContainer } from './interfaces';

import './styles.scss';

// banner pillars container
const BannerPillarsContainer: FC<IBannerPillarsContainer> = ({
  currentPage,
  dir,
  height,
  items,
  pageIndex,
  wrapper,
}) => {
  // render
  return (
    <div
      className="banner-pillars-content"
      ref={wrapper}
      style={{ height }}>
      <div
        data-direction={dir?.dir}
        className="stickyroll-content"
        data-type={pageIndex}>
        {items && items.map((item: IBannerPillarsItem, index: number) =>
          <BannerPillarsItem
            direction={dir?.dir}
            current={currentPage - 1}
            active={pageIndex === index}
            item={item}
            index={index}
            key={index} />)}
      </div>
    </div>
  );
};

export default BannerPillarsContainer;