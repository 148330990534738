import React, { FC, useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import ReactPlayer from 'react-player';

import Icon from '../../../Icon';

import { IListCarrouselMultimediaItem } from './interfaces';

import './styles.scss';

// list carrousel multimedia item
const ListCarrouselMultimediaItem: FC<IListCarrouselMultimediaItem> = ({
  current,
  abstract,
  index,
  image,
  subtype,
  url
}) => {
  const [ pause, setPause ] = useState<boolean>(false);
  const [ videoBg, setVideoBg ] = useState<any>({});

  // get data
  const getData = useCallback(async (url: string) => {
    if (!url) return false;

    const origin = encodeURI(window.location.origin);

    return await axios.get(`https://www.youtube.com/oembed?url=${url}&format=json&origin=${origin}`)
      .then(({ data }) => data);
  }, []);

  // use effect
  useEffect(() => {
    if (subtype === 'tipo2') {
      getData(url).then((data) => setVideoBg(data));
    }

    if (current.current !== index) {
      setPause(false);
    }
  }, [ current, index, url, setVideoBg, getData, subtype ]);

  return (
    <div
      className="list-carrousel-multimedia-item"
      data-type={subtype}>
      <div className="list-carrousel-multimedia-item--info">
        <p className="description">{abstract}</p>
      </div>

      {subtype === 'tipo1'
        && <img
            src={image?.original.src}
            alt="video" />}
      
      {subtype === 'tipo2'
        && <>
            <div
              className="list-carrousel-multimedia-item--bg"
              data-active={pause}
              onClick={() => setPause(!pause)}>
              <img
                className="list-carrousel-multimedia-item--bg--img"
                src={videoBg?.thumbnail_url}
                alt={videoBg?.title} />
              
              <Icon icon="icon-video" />

              <div className="list-carrousel-multimedia-item--info">
                <p className="description">{abstract}</p>
              </div>
            </div>

            <ReactPlayer
              className="list-carrousel-multimedia-item--video"
              height={480}
              width={854}
              playing={pause}
              url={url}
              onEnded={() => setPause(false)} />
          </>}
    </div>
  );
};

export default ListCarrouselMultimediaItem;