import React, { FC } from 'react';
import { usePromiseTracker } from "react-promise-tracker";

import { ILoading } from './interfaces';

import './styles.scss';

// loading
const Loading: FC<ILoading> = () => {
  const { promiseInProgress } = usePromiseTracker();
  
  // render
  return (
    <>
      {promiseInProgress &&
        <div
          className="loading">
          <span className="loader"></span>
        </div>}
    </>
  );
};

export default Loading;