import React, { FC } from 'react';

import WebdoorContent from './WebdoorContent';
import withMediaQueries from '../MediaQueries';

import { IWebdoor } from './interfaces';

import './styles.scss';

// webdoor
const Webdoor: FC<IWebdoor> = ({
  lang,
  queries,
  value
}) => {
  // render
  return (
    <div
      className="webdoor">
      <WebdoorContent
        items={value}
        queries={queries}
        lang={lang} />
    </div>
  );
};

export default withMediaQueries(Webdoor);