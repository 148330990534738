import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { IWebdoorInfoItem } from './interfaces';

import './styles.scss';

// webdoor info item
const WebdoorInfoItem: FC<IWebdoorInfoItem> = ({
  active,
  factoryLink,
  item
}) => {
  const { t }: any = useTranslation();

  // render
  return (
    <div
      className="webdoor-info-item"
      data-active={active}>
      <h1 className="h1">{item?.title}</h1>

      <Link
        to={factoryLink(item?.internal_link?.url)}
        className="webdoor-info-item--btn-link">
          {t('more')}
      </Link>
    </div>
  );
};

export default WebdoorInfoItem;