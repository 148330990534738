import React, { FC } from 'react';

import { IListBusinessSliderMinItem } from './interfaces';

import './styles.scss';

// list business slider min item
const ListBusinessSliderMinItem: FC<IListBusinessSliderMinItem> = ({
  active,
  dir,
  last,
  icon
}) => {
  // render
  return (
    <div
      className="list-business-slider-min-item"
      data-dir={dir}
      data-current={active}
      data-last={last}>
      <img
        alt={icon?.original.alt}
        height={icon?.original.height}
        src={icon?.original.src}
        width={icon?.original.width} />
    </div>
  );
};

export default ListBusinessSliderMinItem;