import React, { FC } from 'react';

import Container from '../../../layout/Container';
import IndicatorNumberBlock from './IndicatorNumberBlock';
import IndicatorNumberPeopleList from './IndicatorNumberPeopleList';
import ParallaxFixDiv from '../../Parallax';
import TextBlock from '../TextBlock';
import TitleBlock from '../TitleBlock';

import { IIndicatorNumberPeople } from './interfaces';

import './styles.scss';

// image
const img = `${process.env.PUBLIC_URL}/images/indicators-people.png`;

// indicator number people
const IndicatorNumberPeople: FC<IIndicatorNumberPeople> = ({ 
  value: {
    label_percent_brazil,
    label_percent_man,
    label_percent_woman,
    label_ppercent_external,
    label_total_people,
    listitems,
    percent_brazil,
    percent_external,
    percent_man,
    percent_woman,
    title,
    total_people,
    unit_total_people,
  }
}) => {
  return (
    <div
      className="indicator-number-people">
      <Container>
        <div className="indicator-number-people--bg">
          <ParallaxFixDiv y={[ 0, 0 ]}>
            <img
              src={img}
              alt={title} />
          </ParallaxFixDiv>
        </div>

        <div className="indicator-number-people--title">
          <TitleBlock title={title} type={3} />
        </div>

        <div className="indicator-number-people--content">
          <div className="indicator-number-people--content--list">
            <TextBlock
              label={label_total_people}
              text={total_people}
              unit={unit_total_people}
              type={2} />

            <IndicatorNumberBlock
              first={{ label: label_percent_man, text: percent_man }}
              last={{ label: label_percent_woman, text: percent_woman }} />

            <IndicatorNumberBlock
              first={{ label: label_percent_brazil, text: percent_brazil }}
              last={{ label: label_ppercent_external, text: percent_external }} />
          </div>

          <IndicatorNumberPeopleList
            items={listitems} />
        </div>
      </Container>
    </div>
  );
};

export default IndicatorNumberPeople;