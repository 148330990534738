import React, { FC, useCallback, useEffect, useState } from 'react';
import axios from 'axios';

import Container from '../../layout/Container';
import NewsContent from './NewsContent';

import UseNavigation from '../../uses/useNavigation';

import { INews } from './interfaces';

import './styles.scss';

// react app base
const { REACT_APP_BASE_API, REACT_APP_PAGINATION_NEWS }: any = process.env;

// news
const News: FC<INews> = ({
  lang
}) => {
  const [page, setPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);

  const { getUrl, getNewsPrefix } = UseNavigation();
  const url = getUrl(lang, REACT_APP_BASE_API);

  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // query
  const query: string = '?type=imprensa.Noticia&fields=image,intro,date&order=-date&offset=';

  // on next prev
  const onNextPrev = useCallback((page: number) => {
    const offset = (page - 1) * REACT_APP_PAGINATION_NEWS;

    setOffset(offset);
    setPage(page);
  }, [setPage, setOffset]);

  // use effect
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const result = await axios.get(
          `${url}/api/v2/pages/${query}${offset}&limit=${REACT_APP_PAGINATION_NEWS}`
        ).then(({ data }: any) => data);

        setData(result);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(true);
      }
    };

    fetchData();
  }, [url, offset]);

  // render
  return (
    <div
      className="news"
      data-loading={isLoading}>
      <Container>
        <NewsContent
          data={data}
          page={page}
          lang={lang}
          prefix={getNewsPrefix(lang)}
          onNextPrev={onNextPrev}
          perPage={REACT_APP_PAGINATION_NEWS} />
      </Container>

      <div
        className="news--loader"
        data-type="loader">
        <div className="loader"></div>
      </div>
    </div>
  );
};

export default News;