import React, { FC } from 'react';
import Slider from 'react-slick';

import PhotoAlbumItem from '../PhotoAlbumItem';

import { IPhotoAlbumItem } from '../PhotoAlbumItem/interfaces';
import { IPhotoAlbumGallery } from './interfaces';

import { settings } from './settings';

import './styles.scss';

// photo album gallery
const PhotoAlbumGallery: FC<IPhotoAlbumGallery> = ({
  items
}) => {
  // render
  return (
    <div
      className="photo-album-gallery">
      <Slider
        className="slider"
        {...settings}>
        {items.map((item: IPhotoAlbumItem, index: number) =>
          <PhotoAlbumItem {...item} key={index} />)}
      </Slider>
    </div>
  );
};

export default PhotoAlbumGallery;