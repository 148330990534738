import React, { FC } from 'react';

import AccordionItem from './AccordionItem';
import Container from '../../layout/Container';

import { IAccordion } from './interfaces';
import { IAccordionItem } from './AccordionItem/interfaces';

import './styles.scss';

// accordion
const Accordion: FC<IAccordion> = ({
  children = false,
  value: { 
    listitems
  }
}) => {
  // render
  return (
    <div className="accordion">
      <Container type={2}>
        {listitems && listitems.map((item: IAccordionItem, index: number) =>
          <AccordionItem children={children} {...item} key={index} />)}
      </Container>
    </div>
  );
};

export default Accordion;