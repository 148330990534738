import React, { FC, useRef } from 'react';

import UseClickOutSide from '../../../uses/useClickOutSide';

import HeaderMenu from '../HeaderMenu';
import HeaderTopSubmenuMobile from '../HeaderTop/HeaderTopSubmenuMobile';

import { IHeaderMenuMobile } from './interfaces';

import './styles.scss';

// header menu mobile
const HeaderMenuMobile: FC<IHeaderMenuMobile> = ({
  mainMenu,
  lang,
  routes,
  open,
  setOpen,
}) => {
  const element: any = useRef<any>(null);

  // click out
  UseClickOutSide(element, () => {
    if (open)
      setOpen(false);
  });
  
  // render
  return (
    <div
      className="header-menu-mobile"
      ref={element}
      data-active={open}>
        <HeaderMenu
          lang={lang}
          routes={mainMenu} />

        <HeaderTopSubmenuMobile
          lang={lang}
          routes={routes.filter((item: any) => item.navegacao === 'secundaria')}
          open={open}
          setOpen={setOpen} />
    </div>
  );
};

export default HeaderMenuMobile;