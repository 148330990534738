import React, { FC } from 'react';

import { IWebdoorSubItem } from './interfaces';

import './styles.scss';

// webdoor sub item
const WebdoorSubItem: FC<IWebdoorSubItem> = ({
  current,
  dir,
  last,
  item
}) => {
  return (
    <div
      className="webdoor-sub-item"
      data-active={current}
      data-dir={dir}
      data-last={last}>
        <img src={item?.image.original.src} alt="Webdoor" />
    </div>
  );
};

export default WebdoorSubItem;