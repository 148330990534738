import React, { FC } from 'react';

import Container from '../../../../layout/Container';
import ListBusinessInfo from '../ListBusinessInfo';
import ListBusinessSlider from '../ListBusinessSlider';
import SliderBase from '../../../Slider/Base';

import { IListBusinessContent } from './interfaces';

import './styles.scss';

// list business content
const ListBusinessContent: FC<IListBusinessContent> = ({
  current,
  items,
  direction,
  last,
  onPrevNext,
  setCurrent,
  title
}) => {
  return (
    <div
      className="list-business-content">
      <Container>
        <ListBusinessInfo
          current={current}
          count={items.length}
          items={items}
          onPrevNext={onPrevNext}
          title={title} />
        
        <ListBusinessSlider
          direction={direction}
          current={current}
          last={last}
          items={items}
          setCurrent={setCurrent} />
      </Container>
    </div>
  );
};

export default SliderBase(ListBusinessContent);