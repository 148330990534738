import React, { FC } from 'react';
import Slider from 'react-slick';

import Container from '../../../layout/Container';
import Card from '../../Card';
import ListCarrouselBusinessPhotoAlbumButton from './ListCarrouselBusinessPhotoAlbumButton';

import { settings } from './settings';

import { IListCarrouselBusinessPhotoAlbum } from './interfaces';

import './styles.scss';

// card gallery
const ListCarrouselBusinessPhotoAlbum: FC<IListCarrouselBusinessPhotoAlbum> = ({ value: { listitems } }) => {
  return (
    <div
      className="list-carrousel-business-photo-album">
      <Container>
        <Slider
          className="slider"
          {...settings}
          nextArrow={<ListCarrouselBusinessPhotoAlbumButton type="right" />}
          prevArrow={<ListCarrouselBusinessPhotoAlbumButton type="left" />}>
          {listitems && listitems.map((item: any, index: number) =>
            <Card {...item} key={index} />)} 
        </Slider> 
      </Container>
    </div>
  );
};

export default ListCarrouselBusinessPhotoAlbum;