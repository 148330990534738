import { FC, useContext } from 'react';

import { MainContext } from '../../providers/MainProvider';

import { IPage } from './interfaces';

import './styles.scss';

// page
const Page: FC<IPage> = ({
  data
}) => {
  const { onLoadPage } = useContext(MainContext);

  // render
  return (
    <div className="page">{onLoadPage(data)}</div>
  );
};

export default Page;