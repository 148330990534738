import React, { FC } from 'react';

import ParallaxFixDiv from '../../Parallax';

import { IImageSideBySideItem } from './interface';

import './styles.scss';

// image side by side item
const ImageSideBySideItem: FC<IImageSideBySideItem> = ({ 
  original: { src, alt },
  type
}) => {
  // render
  return (
    <div
      className="image-side-by-side-item">
      <ParallaxFixDiv y={type === 1 ? [ 10, -10 ] : [ -10, 10 ]}>
        <img
          src={src}
          alt={alt} />
      </ParallaxFixDiv>
    </div>
  );
};

export default ImageSideBySideItem;