import React, { FC, useState } from 'react';
import { createPortal } from 'react-dom';

import IconSearch from '../../assets/icons/search';
import SearchForm from './SearchForm';

import { ISearch } from './interfaces';

import './styles.scss';

// search
const Search: FC<ISearch> = ({
  lang
}) => {
  // modal
  const modal: any = document.getElementById('search');

  const [ open, setOpen ] = useState<boolean>(false);

  // render
  return (
    <div
      className="search">
      <button
        className="btn search--btn"
        onClick={() => setOpen(true)}>
        <IconSearch />
      </button>

      {open === true && createPortal(<SearchForm
        lang={lang}
        open={open}
        setOpen={setOpen} />, modal)}
    </div>
  );
};

export default Search;