import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import PageSearchResult from './PageSearchResult';
import PageSearchForm from './PageSearchForm';

import Footer from '../../layout/Footer';
import Header from '../../layout/Header';

import { IPageSearch } from './interfaces';

// page search
const PageSearch: FC<IPageSearch> = ({
  lang
}) => {
  const { term }: any = useParams();
  const [ search, setSearch ]: any = useState<any>('');

  // use effect
  useEffect(() => {
    if (term !== '') {
      setSearch(term);
    }
  }, [ term ]);

  // render
  return (
    <>
      <Header type={3} />
      
      <PageSearchForm
        lang={lang}
        value={search}
        onChange={setSearch} />

      <PageSearchResult
        lang={lang}
        value={search} />

      <Footer />
    </>
  );
};

export default PageSearch;