import React, { createContext, FC, useCallback, useState } from 'react';

import { IParallaxPageContext, IParallaxPageProvider } from './interfaces';

// parallax page context
const ParallaxPageContext = createContext({} as IParallaxPageContext);

// parallax page provider
const ParallaxPageProvider: FC<IParallaxPageProvider> = ({ children }) => {
  // dir
  const [ dir, setDir ]: any = useState<any>({ value: 0, dir: 'next' });

  const [ elementProps, setElementProps ]: any = useState<any>({});

  // set position
  const setPosition = useCallback((position: number, props: any) => {
    setElementProps({ ...props, position });
    setDir({ value: position, dir: position > dir.value ? 'next' : 'prev' });
  }, [ setDir, dir ]);

  // render
  return (
    <ParallaxPageContext.Provider
      value={{
        dir,
        elementProps,
        setPosition
      }}>
        {children}
    </ParallaxPageContext.Provider>
  );
};

export { ParallaxPageContext, ParallaxPageProvider };
export default ParallaxPageProvider;