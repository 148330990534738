import axios from 'axios';
import React from 'react';
import useSWR from 'swr';

import Accordion from '../../Accordion';
import ListFiles from '../../List/ListFiles';

import { ICentralDocsContainer } from './interaces';

import './styles.scss';

// fetcher
const fetcher = (url: string) => axios.get(url).then(({ data }: any) => data);

// react app base
const { REACT_APP_BASE_API }: any = process.env;

const CentralDocsContainer: React.FC<ICentralDocsContainer> = ({
  company,
  period,
}) => {
  // companies
  const { data: docs } = useSWR(
    `${REACT_APP_BASE_API}/api/v2/centraldoc/documentos?e=${company?.value}&p=${period?.value}`, fetcher);

  // render
  return (
    <div className="central-docs-container">
      {Array.isArray(docs) && docs.map((doc, index: number) => {
        if (!doc?.documentos.length) return true
        
        return <Accordion
          children
          key={index}
          value={{
            listitems: [{
              body: <ListFiles value={{
                "title": "",
                "listitems": doc?.documentos.map((item: any) => {
                  return {
                    title: item.titulo,
                    file_item: {
                      extension: '',
                      size: item.arquivo.file_size,
                      title: item.arquivo.title,
                      id: item.id,
                      url: item.arquivo.download_url.replace(REACT_APP_BASE_API, '')
                    }
                  }
                })
              }} />,
              title: doc.nome
            }]
          }}
        />
      })}
    </div>
  );
};

export default CentralDocsContainer;