import React, { FC } from 'react';

import Container from '../../../layout/Container';
import ParallaxFixDiv from '../../Parallax';
import TextBlock from '../TextBlock';
import TitleBlock from '../TitleBlock';

import { IIndicatorEconomic } from './interfaces';

import './styles.scss';

const { PUBLIC_URL } = process.env;
const img = `${PUBLIC_URL}/images/indicators-economy.png`;

// indicators economic
const IndicatorsEconomic: FC<IIndicatorEconomic> = ({
  value: {
    currency,
    recipe,
    number_recipe,
    volume_recipe,
    ebtida,
    number_ebtida,
    volume_ebtida,
    title,
    subtitle
  }
}) => {
  // render
  return (
    <div
      className="indicators-economy">
      <Container>
        <div className="indicators-economy--bg">
          <ParallaxFixDiv y={[ 0, 0 ]}>
            <img
              src={img} 
              alt={title} />
          </ParallaxFixDiv>
        </div>

        <div className="indicators-economy--title">
          <TitleBlock
            type={1}
            title={title}
            subtitle={subtitle} />

          
        </div>

        <div className="indicators-economy--content">
          <TextBlock
            title={recipe}
            text={number_recipe}
            label={volume_recipe}
            unit={currency} />

          <TextBlock
            title={ebtida}
            text={number_ebtida}
            label={volume_ebtida}
            unit={currency} />
        </div>
      </Container>
    </div>
  );
};

export default IndicatorsEconomic;