// file pdf
const FilePDF = () => {
  return (
  <svg
    enableBackground="new 0 0 41.7 51"
    viewBox="0 0 41.7 51"
    xmlSpace="preserve">
    <path fill="#0056A1" d="m40.8 14.2c0-0.3-0.1-0.6-0.4-0.8l-11-13c-0.2-0.3-0.6-0.4-1-0.4h-23.4c-2.4 0-4.3 2-4.3 4.3v42.3c0 2.4 2 4.3 4.4 4.3h31.4c2.4 0 4.4-1.9 4.4-4.3l-0.1-32.4zm-11.1-10.1l7.6 9h-7.6v-9zm6.7 44.7h-31.4c-1.2 0-2.2-1-2.2-2.2v-42.3c0-1.2 1-2.2 2.2-2.2h22.4v12c0 0.6 0.5 1.1 1.1 1.1h10v31.4c0.1 1.3-0.9 2.2-2.1 2.2z"/>
    <path fill="#E9533F" d="m30.8 33.8h-20.1c-2.4 0-4.4-1.9-4.4-4.3v-5.8c0-2.4 2-4.3 4.4-4.3h20.1c2.4 0 4.4 1.9 4.4 4.3v5.8c-0.1 2.3-2 4.3-4.4 4.3zm-20.1-12.3c-1.2 0-2.2 1-2.2 2.2v5.8c0 1.2 1 2.2 2.2 2.2h20.1c1.2 0 2.2-1 2.2-2.2v-5.8c0-1.2-1-2.2-2.2-2.2h-20.1z"/>
    <path fill="#E9533F" d="m14.1 23c0.5 0 0.9 0.1 1.3 0.2s0.7 0.3 0.9 0.5 0.4 0.5 0.5 0.7c0.1 0.3 0.2 0.6 0.2 0.9 0 0.4-0.1 0.7-0.2 1s-0.3 0.5-0.5 0.8c-0.2 0.2-0.5 0.4-0.9 0.5s-0.8 0.2-1.3 0.2h-0.9v2.4h-1.7v-7.2h2.6zm0 3.5c0.4 0 0.7-0.1 0.9-0.3s0.3-0.5 0.3-0.9c0-0.2 0-0.3-0.1-0.4 0-0.1-0.1-0.2-0.2-0.3s-0.2-0.2-0.4-0.2c-0.2-0.1-0.3-0.1-0.5-0.1h-0.9v2.2h0.9z"/>
    <path fill="#E9533F" d="m24.3 26.5c0 0.5-0.1 1-0.3 1.4s-0.4 0.8-0.8 1.1c-0.3 0.3-0.7 0.6-1.2 0.7-0.5 0.2-1 0.3-1.5 0.3h-2.8v-7h2.8c0.6 0 1.1 0.1 1.5 0.3 0.5 0.2 0.8 0.4 1.2 0.7 0.3 0.3 0.6 0.7 0.8 1.1s0.3 0.9 0.3 1.4zm-1.7 0c0-0.4 0-0.7-0.1-0.9-0.1-0.3-0.2-0.5-0.4-0.7s-0.4-0.3-0.6-0.5c-0.2-0.1-0.5-0.2-0.8-0.2h-1.1v4.6h1.1c0.3 0 0.6-0.1 0.8-0.2s0.5-0.3 0.6-0.5c0.2-0.2 0.3-0.4 0.4-0.7 0.1-0.2 0.1-0.5 0.1-0.9z"/>
    <path fill="#E9533F" d="M26.9,24.2V26h2.4v1.3h-2.4v2.8h-1.7V23h4.6v1.3H26.9z"/>
  </svg>
  );
};

export default FilePDF;