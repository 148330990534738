// icon email
const IconEmail = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.9 17.4">
      <path d="M.9 17.1h20c.4 0 .8-.3.8-.8V.8l-.1-.1-.1-.1H.5S.4.6.4.7L.3.8v15.6c-.1.3.2.7.6.7zm.8-1.5V3l7.1 7.1c.4.4 1.3.9 2.2.9.7 0 1.4-.2 2.2-.9 1.2-1.1 5-5 7.1-7v12.5H1.7zM2.8 2h16.4l-7 7c-1.1.9-2.2.2-2.4 0l-7-7z" fill="#0056a1"/>
      <path d="M15.7 10.3c-.3-.3-.8-.3-1.1.1-.3.3-.3.8.1 1.1l2.6 2.4c.1.1.3.2.5.2s.4-.1.6-.2c.3-.3.3-.8-.1-1.1l-2.6-2.5zm-9.5 0l-2.4 2.4c-.3.3-.3.8 0 1.1.2.1.4.2.6.2.2 0 .4-.1.5-.2l2.4-2.4c.3-.3.3-.8 0-1.1-.3-.3-.8-.3-1.1 0z" fill="#e9533f"/>
    </svg>
  );
};

export default IconEmail;