import React, { FC, useCallback } from 'react';
import clamp from 'lodash/clamp';
import { useGesture } from 'react-use-gesture';

import Container from '../../../layout/Container';
import SliderBase from '../../Slider/Base';
import WebdoorNext from '../WebdoorNext';
import WebdoorInfo from '../WebdoorInfo';
import WebdoorItem from '../WebdoorItem';
import WebdoorSub from '../WebdoorSub';

import { IWebdoorContent } from './interfaces';

import './styles.scss';

// Webdoor content
const WebdoorContent: FC<IWebdoorContent> = ({
  current,
  items,
  direction,
  lang,
  last,
  queries,
  onPrevNext,
  setCurrent
}) => {
  // factory link
  const factoryLink = useCallback((url: string) => {
    const src: string = url.replace(/.*\/\/[^/]*/, '');

    return `/${lang}${src.slice(0, -1)}`;
  }, [ lang ]);

  // drag on gesture
  const dragOnGesture = useCallback(({
    down,
    movement: [mx],
    direction: [xDir],
    distance,
    cancel
  }: any) => {
    if (down && distance > 15) {
      const value = clamp(current + (xDir > 0 ? 1 : -1), 0, items.length - 1);
      cancel(setCurrent(value));
    }
  }, [ current, items, setCurrent ]);

  // drag
  const drag = useGesture({
    onDrag: dragOnGesture
  });

  // render
  return (
    <>
      <div
        className="webdoor-content">
        {items && items.map((item: any, index: number) => (
          <WebdoorItem
            active={current === index}
            drag={(queries === 'xs' || queries === 'sm') ? drag : {}}
            factoryLink={factoryLink}
            dir={direction}
            item={item}
            last={last === index}
            lang={lang}
            key={index} />))}
      </div>

      {items.length > 1 &&
        <WebdoorSub
          current={current < (items.length - 1) ? current + 1 : 0}
          dir={direction}
          last={current}
          items={items} />}

      <Container>
        <WebdoorInfo
          current={current}
          last={last}
          factoryLink={factoryLink}
          onPrevNext={onPrevNext}
          setCurrent={setCurrent}
          items={items} />

        {items.length > 1 &&
          <WebdoorNext onClick={() => onPrevNext('next')} />}
      </Container>
    </>
  )
};

export default SliderBase(WebdoorContent);
