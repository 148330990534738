import React, { FC } from 'react';

import Icon from '../../../Icon';

import { IListNewsControl } from './interfaces';

import './styles.scss';

// list news control
const ListNewsControl: FC<IListNewsControl> = ({
  currentSlide = 0,
  onClick,
  slideCount = 0,
  type,
}) => {
  // render
  return (
    <button
      className={`btn list-news-control-button ${type}`}
      data-disabled={type === 'left' ? currentSlide <= 0 : currentSlide >= (slideCount - 1) || !onClick}
      onClick={onClick}>
      <Icon className={type} icon="icon-arrow-dg" />
    </button>
  );
};

export default ListNewsControl;