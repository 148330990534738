import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useStickyroll } from '@stickyroll/hooks';

import BannerPillarsContainer from './BannerPillarsContainter';

import { ParallaxPageContext } from '../../providers/ParallaxPage';

import useDimension from '../../uses/useDimension';
import UseWindowSize from '../../uses/useWindowSize';

import { IBannerPillars } from './interfaces';

import './styles.scss';

// max top
const maxTop = 148;

// banner pillars
const BannerPillars: FC<IBannerPillars> = ({
  value
}) => {
  const { dir, setPosition } = useContext(ParallaxPageContext);
  const [ top, setTop ] = useState<number>(0);

  const element = useRef<any>(null);
  const props = useDimension(element);
  const size = UseWindowSize();
  
  const { listitems }: any = value[0];

  // wrapper
  const [ wrapper, {
    height, 
    currentPage, 
    pageCount, 
    pageIndex, 
    progress
  }] = useStickyroll({
    pages: listitems.length
  });

  // on end
  const onEnd = useCallback(() =>
    (progress * pageCount) >= pageCount, [ pageCount, progress ]);

  // set position
  const onScrollHandle = useCallback(() => {
    const position = window.pageYOffset;
    const height = window.innerHeight;
    const mobile = size.width <= 1024 ? 55 : maxTop;

    if (element.current) {
      const val = ((position / height) * mobile) + mobile;
      const value = (val < mobile) ? val : mobile;
      
      setTop(value);
      setPosition(position, {
        ...props,
        currentPage,
        pageCount, 
        view: size,
      });
    }

    return true;
  }, [ setTop, size, setPosition, props, currentPage, pageCount ]);

  // use effect
  useEffect(() => {
    window.addEventListener('scroll', onScrollHandle, { passive: true });

    return () => {
      window.removeEventListener('scroll', onScrollHandle);
    };
  }, [ onScrollHandle ]);
  
  // render
  return (
    <div
      className="banner-pillars"
      ref={element}
      data-active={progress > 0}
      data-last={onEnd()}
      style={{ top: -top }}>
      <BannerPillarsContainer
        currentPage={currentPage}
        dir={dir}
        height={height}
        items={listitems}
        pageIndex={pageIndex}
        wrapper={wrapper} />
    </div>
  );
};

export default BannerPillars;