import React, { FC } from 'react';

import { IListMapItem, IListMapItemSub } from './interfaces';

import './styles.scss';

// list map item
const ListMapItem: FC<IListMapItem> = ({
  listsubitems,
  title
}) => {
  // render
  return (
    <div
      className="list-map-item">
      <p className="list-map-item--title">{title}</p>

      <ul
        className="list-map-item--list">
        {listsubitems && listsubitems.map((item: IListMapItemSub, index: number) =>
          <li className="list-map-item--list--item" key={index}>
            <p className="name">{item.title}</p>
          </li>)}
      </ul>
    </div>
  );
};

export default ListMapItem;