import axios from 'axios';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import Select from '../../Form/Select';
import { ICentralDocsForm } from './interfaces';

import './styles.scss'

// fetcher
const fetcher = (url: string) => axios.get(url)
  .then(({ data }: any) => data.map(({ id, nome }: any) => {
      return {
        value: id, label: nome
      }
    }
  ));

// react app base
const { REACT_APP_BASE_API }: any = process.env;

// central docs form
const CentralDocsForm: React.FC<ICentralDocsForm> = ({
  company,
  lang,
  setCompany,
  period,
  setPeriod
}) => {
  const langFix = lang === 'pt' ? 'pt-br' : lang; // lang
  const { t } = useTranslation();

  // companies
  const { data: companies } = useSWR(
    `${REACT_APP_BASE_API}/api/v2/centraldoc/empresas?l=${langFix}`, fetcher);

  // periods
  const { data: periods } = useSWR(
    `${REACT_APP_BASE_API}/api/v2/centraldoc/periodos?l=${langFix}`, fetcher);

  // render
  return (
    <div className="central-docs-form">
      <div className="central-docs-form--select">
        <Select
          defaultText={!company ? t('form.select.select_company') : ''}
          options={companies}
          handleChange={setCompany}
          selectedOption={company}
          translate={false}
        />

        <Select
          defaultText={!period ? t('form.select.select_period') : ''}
          options={periods}
          handleChange={setPeriod}
          selectedOption={period}
          translate={false}
        />
      </div>
    </div>
  );
};

export default CentralDocsForm;