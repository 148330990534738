import React, { FC } from 'react';

import Text from '../../../Typography/Text';

import { ICommunicationsItemInfo } from './interfaces';

import './styles.scss';

// communications item data
const CommunicationsItemInfo: FC<ICommunicationsItemInfo> = ({
  title,
  summary
}) => {
  // render
  return (
    <div
      className="communications-item-info">
      <p className="title">{title}</p>
      <Text attrs={{ className: 'description' }} type="danger" text={summary} />
    </div>
  );
};

export default CommunicationsItemInfo;