import React, { FC } from 'react';

import Icon from '../Icon';
import Text from '../Typography/Text';

import { ILinkIcon } from './interfaces';

// link icon
const LinkIcon: FC<ILinkIcon> = ({
  className,
  icon,
  href,
  props,
  children
}) => {
  return (
    <a
      className={className}
      href={href}
      {...props}>
      <Icon icon={icon} />
      <Text attrs={{ className: 'text' }}>{children}</Text>
    </a>
  );
};

export default LinkIcon;