import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { Logo } from '../../../../assets/icons/logo';

import { IHeaderTopLogo } from './interfaces';

import './styles.scss';

// header top logo
const HeaderTopLogo: FC<IHeaderTopLogo> = ({
  min
}) => {
  return (
    <div
      className="header-top-logo"
      data-min={min}>
      <Link className="logo" to={`/`}>
        <div className="logo-image"></div>
      </Link>
    </div>
  );
};

export default HeaderTopLogo;