import axios from 'axios';

// data
const fetchPage = async (url: string, id: number) => {
  const data = await axios.get(`${url}/api/v2/pages/${id}`)
    .then(({ data, status }) => {
      return {
        page: data,
        status: status,
        isLoading: !status,
      };
  });
  
  return data;
};

// use api
async function UsePage(base: string, home?: boolean, slug?: string) {
  if (!home) {
    const home = await axios.get(`${base}/api/v2/pages/?type=home.HomePage`)
      .then(({ data }: any) => data.items[0]);

    return fetchPage(base, home.id);
  };

  // render
  return axios.get(`${base}/api/v2/pages/?slug=${slug}`)
    .then(async ({ data, status }) => {
      if (data) {
        const { items }: any = data;
        const page = items[0];

        if (page instanceof Object) {
          return fetchPage(base, page.id);
        }
      }

      // render
      return {
        page: data,
        status: status,
        isLoading: !status,
      };
    });
};

export default UsePage;