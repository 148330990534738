import React, { FC } from 'react';

import ReportsItemInfo from '../ReportsItemInfo';

import { IReportsItem } from './interfaces';

import './styles.scss';

// reports item
const ReportsItem: FC<IReportsItem> = (props) => {
  const {
    image,
    subtype
  }: any = props;
  // render
  return (
    <div
      className="reports-item"
      data-type={subtype}>
      <div className="reports-item--image">
        {image && <img
          src={image?.original.src}
          alt="" />}
      </div>

      <ReportsItemInfo {...props} />
    </div>
  );
};

export default ReportsItem;