import React, { FC, useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';

import News from '../../components/News';
import WebdoorInternal from '../../components/WebdoorInternal';

import Footer from '../../layout/Footer';
import Header from '../../layout/Header';

import UseNavigation from '../../uses/useNavigation';
import UseNews from '../../uses/useNews';

import { IPageNews } from './interfaces';

// vars
const { REACT_APP_BASE_API }: any = process.env;

// page new
const PageNews: FC<IPageNews> = ({
  lang,
  location
}) => {
  const { getUrl } = UseNavigation();
  const [ data, setData ] = useState<any>(null);

  const url: string = getUrl(lang, REACT_APP_BASE_API);

  // use effect
  useEffect(() => {
    const slug = location?.pathname.split(`/`);

    const page = async () => await UseNews(url, slug[slug.length - 1])
      .then((data) => setData(data));

    trackPromise(page());
  }, [ url, location ]);

  // render
  return (
    <>
      <Header type={2} />
      
      {data?.page &&
        <WebdoorInternal
          image={data?.page.imagem_do_header}
          title={data?.page.title} />}

      <News
        lang={lang} />

      <Footer /> 
    </>
  );
};

export default PageNews;