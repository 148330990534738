import React, { FC, useCallback, useEffect, useRef } from 'react';
import axios from 'axios';

import Container from '../../layout/Container';
import Text from '../Typography/Text';

import { ITitle } from './interfaces';

import './styles.scss';
import UseNavigation from '../../uses/useNavigation';

// env
const { REACT_APP_BASE_API }: any = process.env;

// title
const Title: FC<ITitle> = ({
  lang,
  value: {
    body,
    title
  }
}) => {
  const { getUrl } = UseNavigation();
  const url: string = getUrl(lang, REACT_APP_BASE_API);

  const element: any = useRef<any>(null);

  // link
  const linkFactory = useCallback((id: string) => {
    return axios({
      method: 'GET',
      url: `${url}/api/v2/pages/?id=${id}`
    }).then(({ data }) => {
      if (data.hasOwnProperty('items') && element.current) {
        const { items } = data;
        const link = element.current.querySelector(`a[id="${id}"]`);

        if (link && items[0]) {
          link.setAttribute('href', `/${lang}/${items[0].meta.slug}`);
        }

        return true;
      }
    });
  }, [ lang, element, url ]);
  
  // links docs
  const linksParser = useCallback(() => {
    if (!body) return '';

    const parser = new DOMParser();

    let content: any = parser.parseFromString(body, "text/html");
    const links = content.getElementsByTagName('a');

    Array.from(links).forEach((item: any) => {
      const type = item.getAttribute('linktype');
      const id: any = item.getAttribute('id');

      if (type === 'page') {
        linkFactory(id);
      }
    });

    return content.body.innerHTML;
  }, [ linkFactory, body ]);

  // use effect
  useEffect(() => {
    if (element.current instanceof Object) {
      linksParser();
    }
  }, [ element, linksParser ]);

  // render
  return (
    <div
      className="title"
      ref={element}>
      <Container type={2}>
        {title &&
          <h3 className="h3 add-line title--title">{title}</h3>}
          
        <Text attrs={{ className: 'title--text' }} type="danger" text={body} />
      </Container>
    </div>
  );
};

export default Title;