import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import Cookies from '../../components/Cookies';
import Text from '../../components/Typography/Text';

import Container from '../Container';
import FooterNetworks from './FooterNetworks';
import FooterTerms from './FooterTerms';
import NetworkShare from '../../components/NetworkShare';
import useBox from '../../uses/useBox';

import { IFooter } from './interfaces';

import networksJSON from './networks.json';
import termsJSON from './terms.json';

import './styles.scss';

// footer
const Footer: FC<IFooter> = ({
  lang,
  type
}) => {
  const { t }: any = useTranslation();

  const { box, ref }: any = useBox();
  const [cookies, setCookie] = useCookies(['novonor_cookie']);

  // render
  return (
    <>
      {/* {(!cookies?.novonor_cookie || cookies?.novonor_cookie === 'false') &&
        <Cookies
          bounding={box}
          cookie={cookies?.novonor_cookie}
          setCookie={setCookie} />}

      {type === 2 &&
        <NetworkShare
          lang={lang?.lang}
          type={2} />} */}

      <footer
        className="footer"
        data-type={type}
        ref={ref}>
        <Container>
          <Link className="copy" to="/">
            <Text attrs={{ className: 'small' }}>{t('copy')}</Text>
          </Link>

          <FooterNetworks items={networksJSON} />

          <FooterTerms items={termsJSON} />
        </Container>
      </footer>
    </>
  );
};

export default Footer;