import React, { FC, useState } from 'react';
import Slider from 'react-slick';

import Container from '../../../layout/Container';
import ListCarrouselMultimediaArrow from './ListCarrouselMultimediaArrow';
import ListCarrouselMultimediaItem from './ListCarrouselMultimediaItem';

import { IListCarrouselMultimedia } from './interfaces';

import { settings } from './settings';

import './styles.scss';

// list carrousel multimedia
const ListCarrouselMultimedia: FC<IListCarrouselMultimedia> = ({ 
  value: { 
    listitems
  }
}) => {
  const [ slick, setSlick ] = useState<any>({ current: 0, last: null });

  // config
  const configSettings = {
    ...settings,
    beforeChange: (current: number, next: number) => setSlick({ last: current, current: next }),
    afterChange: (current: number) => setSlick({ ...slick, next: current })
  };

  // render
  return (
    <div
      className="list-carrousel-multimedia">
      <Container>
        {listitems && <Slider
            className="slider"
            nextArrow={<ListCarrouselMultimediaArrow type="next" />}
            prevArrow={<ListCarrouselMultimediaArrow type="prev" />}
            {...configSettings}>
            {listitems && listitems.map((item: any, index: number) =>
              <ListCarrouselMultimediaItem
                {...item}
                current={slick}
                index={index}
                key={index} />)} 
          </Slider>}
      </Container>
    </div>
  );
};

export default ListCarrouselMultimedia;