// global icon
const Global = () => {
  return (
    <svg
      enableBackground="new 0 0 20 19"
      viewBox="0 0 20 19"
      xmlSpace="preserve">
      <path fill="#0056A1" d="m10 0.5c-5.2 0-9.4 4.1-9.4 9s4.2 9.1 9.4 9.1 9.4-4.1 9.4-9.1-4.2-9-9.4-9zm0 16.6c-4.3 0-7.9-3.4-7.9-7.6s3.5-7.6 7.9-7.6c4.3 0 7.8 3.4 7.8 7.6s-3.5 7.6-7.8 7.6z"/>
        <path fill="#E9533F" d="m10.2 8.6zm1.9 1.5z"/>
      <polygon fill="#0056A1" points="10.9 10.1 11 10.1 10.9 10"/>
      <path fill="#0056A1" d="m11.7 8.9c0-0.1-0.1-0.2-0.2-0.3h0.4c0.1-0.1 0.1-0.3 0.1-0.3v-0.3l1-1h0.1c0.1 0 0.2 0 0.3-0.1v-0.3h0.1 0.1c0.1 0 0.1 0.1 0.2 0.1h0.5l0.2-0.2v-0.1c0-0.2-0.1-0.2-0.2-0.3 0 0-0.1-0.1-0.2-0.1v-0.1c0-0.1-0.1-0.2-0.2-0.2 0 0-0.1 0-0.1-0.1-0.1-0.1-0.3-0.3-0.5-0.4-0.1 0-0.1-0.1-0.2-0.1-0.1-0.1-0.3-0.2-0.5-0.3h-0.1c-0.1 0-0.1-0.1-0.2-0.1h-0.3-0.1c-0.2 0.1-0.4 0.2-0.4 0.5v0.5s-0.1 0-0.1-0.1c-0.1-0.1-0.2-0.1-0.4-0.2-0.1 0-0.2-0.1-0.2-0.2 0 0 0.1 0 0.1-0.1 0.1 0 0.1 0 0.2-0.1 0.1 0 0.2-0.1 0.2-0.2 0.1-0.2 0.2-0.4 0.1-0.6-0.2-0.2-0.4-0.3-0.6-0.3-0.3-0.1-0.6 0-0.8 0h-0.4-0.1-0.2c-0.1 0-0.1 0.1-0.2 0.1h-0.3c-0.1 0-0.1 0-0.2-0.1-0.1 0-0.1 0-0.2-0.1l-0.1-0.1c0-0.1-0.1-0.1-0.1-0.1s-0.1 0-0.1-0.1h-0.1-0.1c-0.3-0.1-0.6-0.1-1 0h-0.1-0.2-0.5c-0.3-0.1-0.6 0-0.8 0.3-0.2 0.2-0.3 0.4-0.4 0.6v0.1 0.2c0 0.1-0.1 0.2-0.1 0.3l-0.1 0.1v0.1s0 0.1-0.1 0.1v0.2l0.3 0.4 0.1-0.1c0.2-0.2 0.8-0.3 1.1-0.2 0.1 0.1 0.2 0.1 0.3 0.1s0.2 0 0.2 0.1l0.2 0.2c-0.1 0.2 0 0.6 0.1 0.9 0.1 0.2 0.3 0.5 0.5 0.7l0.1 0.1c0 0.1 0.1 0.2 0.3 0.3 0 0.1 0 0.2 0.1 0.3 0.2 0.2 0.3 0.4 0.3 0.4 0 0.2 0.1 0.2 0.2 0.2h0.4l0.1 0.1c0.1 0.1 0.2 0.2 0.3 0.4 0 0.1 0.1 0.3 0.2 0.4l2.6-0.8c-0.1-0.1-0.2-0.1-0.3-0.1zm-0.3-0.4h-0.2s-0.1 0-0.1-0.1c0.1 0 0.1-0.1 0.2-0.1l0.1 0.2z"/>
    <path fill="#E9533F" d="m15.7 11.1l-0.3-0.3c-0.2-0.1-0.4-0.2-0.7-0.2 0-0.1-0.1-0.2-0.2-0.3-0.2-0.2-0.6-0.4-1.4-0.4-0.2 0-0.3 0-0.4-0.1 0-0.1-0.1-0.1-0.1-0.2s-0.1-0.2-0.2-0.3l-0.4-0.3-2.5 0.9 1.5 0.2 0.1 0.1-0.1 0.1c-0.1 0.1-0.2 0.2-0.3 0.4-0.2 0.3-0.2 0.6-0.1 0.9 0.1 0.2 0.3 0.7 1 1.1v1.3 0.1c-0.1 0.1-0.2 0.3-0.1 0.5v0.1l-0.1 0.1c-0.1 0.1-0.1 0.3-0.1 0.3 0 0.2 0 0.3 0.1 0.6 0.1 0.2 0.2 0.3 0.4 0.5 0.1 0.1 0.2 0.1 0.3 0.1s0.2 0 0.4-0.1c0 0 0.1 0 0.1-0.1 0.1 0 0.2-0.1 0.2-0.2v-0.2c0-0.1 0-0.2 0.1-0.3l0.1-0.1v-0.3l0.2-0.2c0.1-0.2 0.4-0.5 0.6-0.7 0.3-0.3 0.6-0.6 0.9-0.8 0.1-0.1 0.3-0.2 0.4-0.3h0.2v-0.4c0-0.1 0-0.1 0.1-0.2 0.1-0.2 0.2-0.4 0.3-0.5 0.2 0 0.2-0.3 0.2-0.3l-0.2-0.5z"/>
    </svg>
  );
};

export default Global;