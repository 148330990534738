import React, { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Icon from '../../../../components/Icon';

import { IHeaderTopMenu } from './interfaces';

import IconEmail from '../../../../assets/icons/email';

import './styles.scss';

// header top submenu
const HeaderTopMenu: FC<IHeaderTopMenu> = ({
  items,
  lang,
  queries,
}) => {
  const { t }: any = useTranslation();

  // get icon
  const checkIcon = useCallback((slug: string) =>
    slug === t('contact').toString().toLowerCase(), [ t ]);

  // mobile contact icon
  const MobileContactIcon = useCallback((slug: string) => {
    if (checkIcon(slug)) {
      return <IconEmail />;
    }

    return '';
  }, [ checkIcon ]);

  // render
  return (
    <div
      className="header-top-menu">
      {items && items
        .filter(({ meta: { slug } }) => slug.split('/').length <= 1)
        .map(({ meta: { slug }, title }, index: number) =>
          <li
            className="header-top-menu--item"
            key={index}>
            <Link
              className="header-top-menu--item--link"
              data-icon={checkIcon(slug)}
              to={`/${lang}/${slug}`}>
              {(queries !== 'xlg') && MobileContactIcon(slug)}

              <span className="paragraph strong">{title}</span>

              {(queries !== 'xlg') &&
                <Icon icon="icon-arrow-right" />}
            </Link>
          </li>)}
    </div>
  );
};

export default HeaderTopMenu;