import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router';

import SearchFormTop from './SearchFormTop';
import SearchInput from './SearchInput';

import { ISearchContent } from './interfaces';

import './styles.scss';

// search content
const SearchForm: FC<ISearchContent> = ({
  lang,
  open,
  setOpen
}) => {
  let history = useHistory();

  // on search
  const onSearch = useCallback((value) => {
    history.push(`/${lang}/search/${value}`);
  }, [ history, lang ]);
  
  // render
  return (
    <div
      className="search-form">
      <div
        className="search-form--container">
        <SearchFormTop
          setOpen={setOpen} />
          
        <SearchInput
          open={open}
          setOpen={setOpen}
          onSearch={onSearch} />
      </div>
    </div>
  );
};

export default SearchForm;