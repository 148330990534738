import React, { FC, useCallback, useContext, useEffect, useRef } from 'react';
import { withRouter } from 'react-router';

import ListBusinessContent from './ListBusinessContent';

import { ParallaxPageContext } from '../../../providers/ParallaxPage';

import { IListBusiness } from './interfaces';

import './styles.scss';

// margin top
const marginTop: number = 30;

// list business
const ListBusiness: FC<IListBusiness> = ({
  value,
  location
}) => {
  const { elementProps } = useContext(ParallaxPageContext);
  // element
  const element: any = useRef<any>(null);

  // set properties element
  const setPropertiesElement = useCallback((element: any, props: any) => {
    if (element instanceof Object) {
      for (let key in props) {
        element.style[key] = props[key];
      }
    }
  }, []);

  // on top
  const onTop = useCallback((props: any) => {
    if (element.current instanceof Object) {
      const { view, position, currentPage } = props;
      const list = element.current;
      const pillars = list.previousSibling;

      const item: any = pillars.querySelector('div[data-active="true"] .image');
      
      if (view instanceof Object && item instanceof Object) {
        const height = Math.ceil(item.getBoundingClientRect().height) || 570;

        const mobile = view.width <= 1024 ? 70 : 270;
        const diff = (view.height - (height + (mobile)));
        const last = pillars.getAttribute('data-last');

        if (currentPage > 1) {
          if (last === 'true') {
            const y = (view.height - (height - (marginTop + 20)));

            setPropertiesElement(list, {
              left: 0,
              marginBottom: `-${y}px`,
              position: 'relative',
              top: `-${y}px`,
              transition: 'none' });
          } else {
            setPropertiesElement(list, {
              left: 0,
              marginBottom: 0,
              position: 'fixed',
              top: `calc(100vh - ${diff}px)` });
          }
        } else {
          if ((view.height - position) < (view.height / 2)) {
            setPropertiesElement(list, {
              left: 0,
              marginBottom: 0,
              position: 'fixed',
              top: `calc(100vh - ${diff}px)`,
              transition: 'none' });
          } else {
            setPropertiesElement(list, {
              left: 0,
              marginBottom: 0,
              position: 'relative',
              top: 0,
              transition: 'none' });
          }
        }
      }
    }
  }, [ element, setPropertiesElement ]);

  // effect
  useEffect(() => {
    if (location?.pathname === '/') {
      onTop(elementProps);
    }
  }, [ elementProps, location, onTop ]);

  // render
  return (
    <div
      className="list-business"
      ref={element}>
      <ListBusinessContent
        items={value[0].listitems}
        title={value[0].title} />
    </div>
  );
};

export default withRouter(ListBusiness);