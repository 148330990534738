import React, { FC } from 'react';

import Icon from '../Icon';
import Text from '../Typography/Text';

import { ILinkArrow } from './interfaces';

import './styles.scss';

// link arrow
const LinkArrow: FC<ILinkArrow> = ({
  attrs,
  children
}) => {
  // render
  return (
    <a
      data-type="link-arrow"
      {...attrs}>
      <Icon icon="icon-open" />
      <Text attrs={{ className: 'strong text' }}>{children}</Text>
      <Icon icon="icon-arrow-dg" />
    </a>
  );
};

export default LinkArrow;