export const settings = {
  dots: false,
  arrows: true,
  infinite: false,
  slidesToScroll: 1,
  slidesToShow: 1,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 480,
      settings: {
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    }
  ]
};