import React, { FC } from 'react';

import Icon from '../../../components/Icon';

import { IFooterNetworks } from './interfaces';

import './styles.scss';

// footer networks
const FooterNetworks: FC<IFooterNetworks> = ({ 
  items
}) => {
  // render
  return (
    <div
      className="footer-networks">
      {Array.isArray(items) && items.map(({ icon, url }, index: number) =>
        <li className={`footer-networks--item ${index === 2 ? 'specific-icon' : ''}`} key={index}>
          <a
            className="link"
            href={url}
            target="_blank"
            rel="noreferrer">
            <Icon icon={icon} />
          </a>
        </li>)}
    </div>
  );
};

export default FooterNetworks;