import React, { FC } from 'react';

import { IContainer } from './interfaces';

import './styles.scss';

// container
const Container: FC<IContainer> = ({ children, type }) => {
  return (
    <div
      className="container"
      data-type={type}>
      <div
        className="container-element">{children}</div>
    </div>
  );
};

export default Container;