import React, { FC } from 'react';

import Container from '../../../layout/Container';
import ListFilesItem from './ListFilesItem';

import { IListFiles } from './interfaces';

import './styles.scss';

// list files
const ListFiles: FC<IListFiles> = ({
  value: {
    listitems,
    title,
  }
}) => {
  return (
    <>
      {title && <Container type={2}>
        <div className="list-files--title">{title}</div>
      </Container>}

      <div
        className="list-files" data-type={listitems.length <= 1 ? 'center' : ''}>
        <Container>
          {listitems && listitems.map((item: any, index: number) =>
            <ListFilesItem {...item} key={index} />)}
        </Container>
      </div>
    </>
  );
};

export default ListFiles;