import React, { FC, useCallback } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { parse, format } from 'date-fns';
import { ptBR, es, enUS } from 'date-fns/locale';

import Container from '../../../layout/Container';
import ListNewsControl from './ListNewsControl';
import ListNewsItem from './ListNewsItem';
import Text from '../../Typography/Text';

import { IListNews } from './interfaces';

import { settings } from './settings';

import './styles.scss';

// fetcher
const fetcher = (url: string) => axios.get(url).then(({ data }: any) => data);

// list news
const ListNews: FC<IListNews> = ({
  url,
  lang
}) => {
  const { t }: any = useTranslation();
  const { data }: any = 
    useSWR(`${url}/api/v2/pages/?type=imprensa.Noticia&fields=image,date,intro&limit=9&order=-date`, fetcher);

  // get locale
  const getLocale = useCallback(() => {
    switch (lang?.lang) {
      case 'es':
        return es;
      case 'en':
        return enUS;
      case 'pt':
        default:
          return ptBR
    }
  }, [ lang ]);

  // get date
  const getDate = useCallback((date: string, form: string) => {
    const value = format(parse(date, 'yyyy-MM-dd', new Date()), form, { 
      locale: getLocale()
    });

    return value;
  }, [ getLocale ]);

  // render
  return (
    <div
      className="list-news">
      <Container>
        <div
          className="list-news--title">
          <Text attrs={{ className: 'text' }}>{t('communication')}</Text>
        </div>

        {Array.isArray(data?.items) &&
          <Slider
            {...settings}
            className="slider"
            nextArrow={<ListNewsControl type="right" />}
            prevArrow={<ListNewsControl type="left" />}>
            {Array.isArray(data?.items) &&
              data?.items.slice(0, 8).map((item: any, index: number) =>
              <ListNewsItem
                {...item}
                getDate={getDate}
                lang={lang}
                key={index} />)}
          </Slider>}
      </Container>
    </div>
  );
};

export default ListNews;