import React, { FC } from 'react';

import Container from '../../layout/Container';
import PhotoAlbumGallery from './PhotoAlbumGallery';

import { IPhotoAlbum } from './interfaces';

import './styles.scss';

// photo album
const PhotoAlbum: FC<IPhotoAlbum> = ({
  value: { listitems }
}) => {
  // render
  return (
    <div
      className="photo-album"
      data-type={listitems.length > 3 ? 1 : ''}>
      <Container>
        <PhotoAlbumGallery items={listitems} />
      </Container>
    </div>
  );
};

export default PhotoAlbum;