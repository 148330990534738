import React, { FC, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import { Formik, Form } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';

import InputForm from '../Input/InputForm';
import InputFormLabel from '../Input/InputForm/InputFormLabel';
import InputGroup from '../Input/InputGroup';
import FormSuccess from './FormSuccess';
import Select from '../Select';

import useForm from '../../../uses/useForm';
import UseNavigation from '../../../uses/useNavigation';
import Container from '../../../layout/Container';

import { IFormContact } from './interfaces';

import './styles.scss';

// env
const { REACT_APP_BASE_API, REACT_APP_GOOGLE_CAPTCHA }: any = process.env;

// form contact
const FormContact: FC<IFormContact> = ({
  lang
}) => {
  const recaptchaRef = useRef<any>(null);

  // state
  const [ subjects, setSubject ] = useState<any>([]);
  const [ error, setError ] = useState<boolean>(false);
  const [ selectSubject, setSelectSubject ]: any = useState<any>({ value: '', label: '' });

  const { t }: any = useTranslation();
  const { getUrl }: any = UseNavigation();

  // params
  const location = useLocation();
  const url: string = getUrl(lang, REACT_APP_BASE_API);

  // use form
  const { typesForm } = useForm();

  // initial
  const initialValues: any = {
    recaptcha: '',
    nome: '',
    email: '',
    assunto: '',
    pais: '',
    cidade: '',
    mensagem: '',
    aceita_receber_comunicados: 'true'
  };

  // select subject
  const onSetSubject = (value: string) => setSelectSubject(value);

  // options
  useEffect(() => {
    const api = async () => {
      const subject = await axios({
        method: 'GET',
        url: `${url}/api/v2/formularios/fale-conosco/assuntos`,

      }).then(({ data }) => {
        if (data instanceof Object) {
          return data.map(({ id, nome }: any) => ({ value: id, label: nome }));
        }

        return [];
      });

      if (subject) {
        const params: any = new URLSearchParams(location.search);
        const select_option: any = parseInt(params.get('select_option'));

        if (select_option) {
          const select_search = subject.filter((item: any) => item.value === select_option);

          if (select_search.length > 0) {
            setSelectSubject(select_search[0]);
          }
        } else {
          if (subject.length > 0) {
            const select_default = subject.filter((item: any) => item.ordem === 0);

            if (select_default.length > 0) {
              setSelectSubject(select_default[0]);
            } else {
              setSelectSubject(subject[0]);
            }
          }
        }
        
        setSubject(subject);
      }
    };

    api();
  }, [ location, url ]);

  // render
  return (
    <div
      className="form-contact"
      data-type="contact"
      data-error={error}>
      <Container type={2}>
        <div className="form-contact--container">
          <Formik
            // init
            initialValues={initialValues}
            initialStatus={{ success: false }}
            // validate
            validate={(values: any) => {
              const errors: any = typesForm({ ...values, selectSubject }, 'contact');
              setError(Object.keys(errors).length > 0);
              
              return errors;
            }}
            // on submit
            onSubmit={(values, {
              setSubmitting,
              setStatus,
              resetForm
            }) => {
              setStatus({ success: false });

              const params = values;
              params.assunto = selectSubject.value;
              params.recaptcha = recaptchaRef?.current?.getValue();

              axios.post(`${url}/api/v2/formularios/fale-conosco`, {
                ...params
              }).then(e => {
                resetForm(initialValues);
                setSelectSubject({});
                setStatus({ success: true });
                return e;
              }).catch(e => setStatus({ error: true }));

              setSubmitting(false);
            }}>
            {({
              values,
              errors,
              status,
              isSubmitting,
              setFieldValue,
            }) => (
              <>
                {(status.error === false || status.success === false) &&
                <Form autoComplete="off" data-submitting={isSubmitting}>
                  <InputForm
                    label={t('form.label.name')}
                    name="nome"
                    type="text"
                    error={errors.nome}
                    empty={!values?.nome}
                    required={true} />

                  <InputForm
                    label={t('form.label.email')}
                    name="email"
                    type="email"
                    error={errors.email}
                    empty={!values?.email}
                    required={true} />

                  <InputGroup>
                    <InputFormLabel
                      data-type="select"
                      data-error={errors.assunto}>
                      <Select
                        defaultText={t('form.label.subject')}
                        options={subjects}
                        handleChange={onSetSubject}
                        selectedOption={selectSubject} />

                      <input name="assunto" type="hidden" required={true} />
                    </InputFormLabel>
                  </InputGroup>

                  <InputForm
                    label={t('form.label.country')}
                    name="pais"
                    type="text"
                    error={errors.pais}
                    empty={!values?.pais}
                    required={true} />

                  <InputForm
                    label={t('form.label.city')}
                    name="cidade"
                    type="text"
                    error={errors.cidade}
                    empty={!values?.cidade}
                    required={true} />

                  <InputGroup>
                    <InputForm
                      empty={values?.mensagem}
                      label={t('form.label.your_message')}
                      name="mensagem"
                      type="textarea"
                      error={errors.mensagem}
                      required={true} />
                  </InputGroup>

                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={REACT_APP_GOOGLE_CAPTCHA}
                    onChange={(value: any) => setFieldValue('recaptcha', value)} />
                    
                  {!status.success &&
                    <div className="form-contact--footer">
                      <button
                        className="form-contact--button"
                        type="submit"
                        disabled={isSubmitting || recaptchaRef?.current?.getValue() === ''}>{t('form.send')}</button>
                    </div>}

                  <InputGroup>
                    <InputForm
                      label={t('form.message.communication')}
                      name="aceita_receber_comunicados"
                      type="check" />
                  </InputGroup>
                </Form>}

                {status.success === true && <FormSuccess type="success" />}
                {status.error === true && <FormSuccess type="error" />}
              </>
            )}
          </Formik>
        </div>
      </Container>
    </div>
  );
};

export default FormContact;