import React, { FC } from 'react';

import ListBusinessSliderMinItem from './ListBusinessSliderMinItem';

import { IListBusinessSliderMin } from './interfaces';

import './styles.scss';

// list business slider min
const ListBusinessSliderMin: FC<IListBusinessSliderMin> = ({
  current,
  dir,
  items,
  last,
}) => {
  return (
    <div
      className="list-business-slider-min">
      {items && items.map(( item, index: number ) =>
        <ListBusinessSliderMinItem
          {...item}
          active={current === index}
          dir={dir}
          last={last === index}
          key={index} />)}
    </div>
  );
};

export default ListBusinessSliderMin;