import React, { FC } from 'react';

import { ITitleBlock } from './interfaces';

import './styles.scss';

const { PUBLIC_URL } = process.env;
const bracket = `${PUBLIC_URL}/images/bracket.svg`;

// title block
const TitleBlock: FC<ITitleBlock> = ({
  title,
  type,
  subtitle
}) => {
  return (
    <div
      className="title-block"
      data-subtitle={subtitle !== ''}
      data-type={type}>
      {subtitle &&
        <p className="subtitle">{subtitle}</p>}
        
      <p className="title">{title}</p>
      <img className="bracket" src={bracket} alt="icon" />
    </div>
  );
};

export default TitleBlock;