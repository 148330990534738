import React, { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';

import Text from '../../../Typography/Text';
import UseNavigation from '../../../../uses/useNavigation';

import { IListNewsItem } from './interfaces';

import './styles.scss';

// list news item
const ListNewsItem: FC<IListNewsItem> = ({
  meta,
  lang,
  title,
  image,
  date,
  intro,
  getDate
}) => {
  const { getNewsPrefix }: any = UseNavigation();

  // get image
  const getImage = useCallback(() => {
    if (image instanceof Object) {
      return image.meta.download_url;
    }

    return `${process.env.PUBLIC_URL}/images/placeholder-news.png`;
  }, [ image ]);

  // render
  return (
    <div
      className="list-news-item">
      <Link
        className="link"
        to={`${lang?.lang}/${getNewsPrefix(lang?.lang)}/${meta?.slug}`}>
        <div className="list-news-item--image">
          <img
            alt={title}
            src={getImage()} />
        </div>
        <div className="list-news-item--info">
          <div
            className="list-news-item--date">
            {date &&
              <p className="paragraph">
                <strong>{getDate(date, 'dd')}</strong>
                <span>{getDate(date, 'MMM')}</span>
              </p>}
          </div>

          <p className="title">{title}</p>
          <Text attrs={{ className: 'description' }} text={intro} type="danger" />
        </div>
      </Link>
    </div>
  );
};

export default ListNewsItem;