import React, { FC, useRef, useState } from 'react';

import Icon from '../Icon';
import LangSelectMenu from './LangSelectMenu';
import LangSelectList from './LangSelectList';
import UseClickOutSide from '../../uses/useClickOutSide';

import { ILangSelect } from './interfaces';

import Global from '../../assets/icons/global';

import './styles.scss';

// lang select
const LangSelect: FC<ILangSelect> = ({
  lang
}) => {
  const element: any = useRef<any>(null);
  const [ open, setOpen ] = useState<boolean>(false);

  // click out
  UseClickOutSide(element, () => setOpen(false));
  
  // render
  return (
    <div
      className="lang-select"
      onMouseOver={() => setOpen(true)}
      onMouseOut={() => setOpen(false)}
      data-active={open}
      ref={element}>
      <button
        className="btn lang-select--btn"
        onClick={() => setOpen(!open)}>
        <Global />
        <Icon icon="icon-arrow-down" />
      </button>

      <div className="lang-select--container">
        <LangSelectList
          setOpen={setOpen} />

        <LangSelectMenu
          lang={lang} />
      </div>
    </div>
  );
};

export default LangSelect;