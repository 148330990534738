import Accordion from './Accordion';
import BannerPillars from './BannerPillars';
import CardBusiness from './CardBusiness';
import CallToAction from './CTA';
import CentralDocs from './CentralDocs';
import Communications from './Communications';
import IndicatorEconomic from './Indicators/IndicatorEconomic';
import IndicatorWorldPeople from './Indicators/IndicatorWorldPeople';
import IndicatorNumberPeople from './Indicators/IndicatorNumberPeople';
import ImageFull from './Image/ImageFull';
import ImageText from './ImageText';
import ImageSideBySide from './ImageSideBySide';
import FormContact from './Form/FormContact';
import ListBusiness from './List/ListBusiness';
import ListCarrouselBusinessPhotoAlbum from './List/ListCarrouselBusinessPhotoAlbum';
import ListCarrouselMultimedia from './List/ListCarrouselMultimedia';
import ListFiles from './List/ListFiles';
import ListMap from './List/ListMap';
import ListNews from './List/ListNews';
import MediaVideos from './MediaVideos';
import News from './News';
import Reports from './Reports';
import Profile from './Profile';
import PhotoAlbum from './PhotoAlbum';
import Table from './Table';
import TabMenu from './TabMenu';
import Timeline from './Timeline';
import Title from './Title';
import Webdoor from './Webdoor';
import Video from './Video';

export const TYPE_COMPONENTS = [
  {
    type: "component_title_text",
    componentElement: Title,
  }, {
    type: "component_webdoor",
    componentElement: Webdoor,
  }, {
    type: "component_image_text",
    componentElement: ImageText
  }, {
    type: "component_listcarrousel_photoalbum",
    componentElement: PhotoAlbum
  }, {
    type: "component_image_sidebyside",
    componentElement: ImageSideBySide
  }, {
    type: "component_video",
    componentElement: Video
  }, {
    type: "component_cta",
    componentElement: CallToAction
  }, {
    type: "component_listbusiness",
    componentElement: ListBusiness
  }, {
    type: "component_listcarrousel_multimedia",
    componentElement: ListCarrouselMultimedia
  }, {
    type: "component_listcarrouselbusiness_photoalbum",
    componentElement: ListCarrouselBusinessPhotoAlbum
  }, {
    type: "component_imagem",
    componentElement: ImageFull
  }, {
    type: "component_bannerpillars",
    componentElement: BannerPillars
  }, {
    type: "component_reports",
    componentElement: Reports
  }, {
    type: "component_accordion",
    componentElement: Accordion
  }, {
    type: "component_countrylist",
    componentElement: ListMap
  }, {
    type: "component_files",
    componentElement: ListFiles
  }, {
    type: "component_indicatorseconomic",
    componentElement: IndicatorEconomic
  }, {
    type: "component_worldpeople",
    componentElement: IndicatorWorldPeople
  }, {
    type: "component_numberpeople",
    componentElement: IndicatorNumberPeople
  }, {
    type: "component_profile",
    componentElement: Profile
  }, {
    type: "component_tabmenu",
    componentElement: TabMenu
  }, {
    type: "component_cardbusiness",
    componentElement: CardBusiness
  }, {
    type: "component_timeline",
    componentElement: Timeline
  }, {
    type: "component_list_news",
    componentElement: News
  }, {
    type: "component_listcarrousel_news",
    componentElement: ListNews
  }, {
    type: "component_formulario_fale_conosco",
    componentElement: FormContact
  }, {
    type: "component_list_communicated",
    componentElement: Communications
  }, {
    type: "component_list_videos",
    componentElement: MediaVideos
  }, {
    type: "component_table",
    componentElement: Table
  }, {
    type: "component_centraldoc",
    componentElement: CentralDocs
  }
];