import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import LinkIcon from '../../LinkIcon';

import { IReportsItemInfo } from './interfaces';

import './styles.scss';

// reports item info
const ReportsItemInfo: FC<IReportsItemInfo> = ({
  external_link,
  image: {
    original
  },
  file_gri,
  file_report,
  subtype,
  title,
  year
}) => {
  const { t }: any = useTranslation();

  // render
  return (
    <div
      className="reports-item-info"
      data-type={subtype}>
      <div className="reports-item-info--title">
        <p className="paragraph title">{title}</p>
        <p className="paragraph year">{year}</p>
      </div>

      <div className="reports-item-info--links">
        {external_link && <LinkIcon
          className="action"
          href={external_link}
          props={{ target: '_blank' }}
          icon="icon-open">{t('view.site')}</LinkIcon>}
        
        {file_report && <LinkIcon
          className="action"
          href={file_report?.url}
          props={{ target: '_blank' }}
          icon="icon-file-pdf">{t('download.pdf')}</LinkIcon>}

        {file_gri?.url && <LinkIcon
          className="action"
          href={file_gri?.url}
          icon="icon-file"
          props={{ target: '_blank' }}>{t('book.grid')}</LinkIcon>}
      </div>
    </div>
  );
};

export default ReportsItemInfo;