import { createPortal } from 'react-dom';
import React, { FC, useState } from 'react';

import ProfileCardModal from '../ProfileCardModal';

import IconBio from '../../../assets/icons/bio';

import { IProfileCard } from './interfaces';

import './styles.scss';

// profile card
const ProfileCard: FC<IProfileCard> = ({
  body,
  image,
  title,
  role
}) => {
  const [ open, setOpen ] = useState<boolean>(false);
  const element: any = document.getElementById('search');

  // render
  return (
    <div
      className="profile-card">
      <img
        className="image"
        src={image?.large.src}
        alt={title} />

      <button
        className="btn profile-card--btn"
        onClick={() => setOpen(!open)}>
          <IconBio />
      </button>

      <div className="profile-card--info">
        <p className="title">{title}</p>
        <p className="role">{role}</p>
      </div>

      {open && createPortal(<ProfileCardModal
        title={title}
        role={role}
        body={body}
        toggle={() => setOpen(!open)} />, element)}
    </div>
  );
};

export default ProfileCard;