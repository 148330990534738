import React, { createContext, createElement, FC, ReactNode, useCallback, useContext } from 'react';

import { LangContext } from '../LangProvider';

import { IMainContext, IMainProvider } from './interfaces';

// components
import { TYPE_COMPONENTS } from '../../components';

// main context
const MainContext = createContext({} as IMainContext);

// main provider
const MainProvider: FC<IMainProvider> = ({ children }) => {
  const { lang }: any = useContext(LangContext);

  // find element
  const findElement = useCallback((type): any => 
    TYPE_COMPONENTS.filter((item: any) => type === item.type)[0], []);

  // create element
  const createElementPage = useCallback((element, index) => {
    const { type, value } = element;

    if (value instanceof Object) {
      const component = findElement(type);

      if (component instanceof Object) {
        const { componentElement } = component;

        return createElement(componentElement, {
          lang: lang?.lang, 
          value, 
          key: index, });
      }
    }

    return false;
  }, [ lang, findElement ]);

  // load page
  const onLoadPage = useCallback((page: any) => {
    if (page instanceof Object === false || !page.hasOwnProperty('corpo')) return false;

    return page.corpo.map((item: ReactNode, index: number) => 
      createElementPage(item, index));
  }, [ createElementPage ]);
  
  // render
  return (
    <MainContext.Provider value={{
      onLoadPage
    }}>{children}
    </MainContext.Provider>
  );
};

export { MainContext, MainProvider };
export default MainProvider;