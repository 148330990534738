import React, { FC } from 'react';

import { IPhotoAlbumItem } from './interfaces';

import './styles.scss';

// photo album item
const PhotoAlbumItem: FC<IPhotoAlbumItem> = ({
  image: {
    large: { src, alt }},
    abstract
}) => {
  // render
  return (
    <div
      className="photo-album-item"
      data-empty={abstract === ''}>
      <img
        src={src}
        alt={alt} />

      <p className="paragraph strong">{abstract}</p>
    </div>
  );
};

export default PhotoAlbumItem;