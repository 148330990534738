import React, { FC, useCallback } from 'react';

import { ITimelineItemSliderCurrent } from './interfaces';

import './styles.scss';

// time line item slider current
const TimelineItemSliderCurrent: FC<ITimelineItemSliderCurrent> = ({
  current,
  total
}) => {
  // decimal
  const decimal = useCallback((value: number) => 
    value < 10 ? `0${value}` : value, []);

  // return
  return (
    <div
      className="time-line-item-slider-current">
      <span className="current">{current}</span>
      <span className="total">&nbsp;/&nbsp;{decimal(total)}</span>
    </div>
  );
};

export default TimelineItemSliderCurrent;