import React, { FC } from 'react';

import IndicatorNumberPeopleListItem from './IndicatorNumberPeopleListItem';

import { IIndicatorNumberPeopleListItem } from './IndicatorNumberPeopleListItem/interfaces';
import { IIndicatorNumberPeopleList } from './interfaces';

import './styles.scss';

// indicator number people list
const IndicatorNumberPeopleList: FC<IIndicatorNumberPeopleList> = ({
  items
}) => {
  // render
  return (
    <div
      className="indicator-number-people-list">
      {items && items.map((item: IIndicatorNumberPeopleListItem, index: number) =>
        <IndicatorNumberPeopleListItem {...item} key={index} />)}
    </div>
  );
};

export default IndicatorNumberPeopleList;