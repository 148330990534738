import React, { FC } from 'react';

import { IListFilesItem } from './interfaces';

import FilePDF from '../../../../assets/icons/file-pdf';

import './styles.scss';

// list files item
const ListFilesItem: FC<IListFilesItem> = ({
  title,
  file_item
}) => {
  return (
    <div
      className="list-files-item">
      <a
        className="link"
        href={file_item.url}
        rel="noreferrer"
        target="_blank">
          <p className="title">{title}</p>
          <FilePDF />
      </a>
    </div>
  );
};

export default ListFilesItem;