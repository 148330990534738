import React, { FC, useCallback } from 'react';
import { parse, format } from 'date-fns';

import Icon from '../../../Icon';
import Text from '../../../Typography/Text';

import { INewsItemInfo } from './interfaces';

import './styles.scss';

// news item
const NewsItemInfo: FC<INewsItemInfo> = ({
  intro,
  date,
  lang
}) => {
  const dataFormat = parse(format(new Date(date), 'yyyy-MM-dd'), 'yyyy-MM-dd', new Date());

  // get date
  const getDate = useCallback(() => {
    const value = lang !== 'en' ? format(dataFormat, 'dd/MM/yyyy') : format(dataFormat, 'MM/dd/yyyy');

    return value;
  }, [ dataFormat, lang ]);

  // render
  return (
    <div
      className="news-item-info">
      <div className="news-item-info--year">
        <Icon icon="icon-text-dg" />
        <p className="text">{getDate()}</p>
      </div>
      <div className="news-item-info--description">
        <Text attrs={{ className: 'description' }} type="danger" text={intro} />
      
        <span className="icon-plus"></span>
      </div>
    </div>
  );
};

export default NewsItemInfo;