import axios from 'axios';
import { parse, format } from 'date-fns';
import React, { FC, useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import useSWR from 'swr';

import Icon from '../../Icon';
import MediaVideosItemModal from './MediaVideosItemModal';
import Text from '../../Typography/Text';

import { IMediaVideosItem } from './interfaces';

import './styles.scss';

// fetcher
const fetcher = (url: string) => {
  const origin = encodeURI(window.location.origin);

  return axios.get(`https://www.youtube.com/oembed?url=${url}${origin}`)
    .then(({ data }: any) => data);
};

// news item
const MediaVideosItem: FC<IMediaVideosItem> = ({
  date,
  lang,
  title,
  youtube_url
}) => {
  const [ open, setOpen ] = useState<boolean>(false);
  const [ duration, setDuration ] = useState<any>(0);

  const dataFormat = parse(format(new Date(date), 'yyyy-MM-dd'), 'yyyy-MM-dd', new Date());

  // element
  const element: any = document.getElementById('search');

  // data
  const { data } = useSWR(`${youtube_url}&format=json&origin=`, fetcher);

  // format time
  const formatTime = useCallback((time: number) => {
    let hrs = ~~(time / 3600);
    let mins = ~~((time % 3600) / 60);
    let secs = ~~time % 60;

    let ret = '';

    if (hrs > 0) {
      ret += '' + hrs + ':' + (mins < 10 ? '0' : '');
    }

    ret += '' + mins + ':' + (secs < 10 ? '0' : '');
    ret += '' + secs;

    return ret;
  }, []);  

  // get date
  const getDate = useCallback(() => {
    const value = lang !== 'en' ? format(dataFormat, 'dd/MM/yyyy') : format(dataFormat, 'MM/dd/yyyy');

    return value;
  }, [ dataFormat, lang ]);

  // render
  return (
    <div
      className="media-videos-item"
      onClick={() => setOpen(!open)}>
      <div className="media-videos-item--image">
        <Icon className="icon" icon="icon-video" />
        {duration > 0 && <span className="duration">{formatTime(duration)}</span>}

        <img src={data?.thumbnail_url} alt={title} />
      </div>

      <div className="media-videos-item--container">
        <div className="media-videos-item--container--title">
          <Icon className="icon" icon="icon-text-dg" />
          <p className="date">{getDate()}</p>
        </div>

        <Text attrs={{ className: 'description '}} text={title} type="danger" />
      </div>

      {open && createPortal(<MediaVideosItemModal
        url={youtube_url}
        onDuration={setDuration}
        toggle={() => setOpen(!open)} />, element)}
    </div>
  );
};

export default MediaVideosItem;