import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import NewsItemInfo from './NewsItemInfo';

import { INewsItem } from './interfaces';

import './styles.scss';

// news item
const NewsItem: FC<INewsItem> = ({
 meta,
 image,
 lang,
 prefix,
 title,
 date,
 intro
}) => {
  // render
  return (
    <Link
      className="news-item"
      to={`/${lang}/${prefix}/${meta.slug}`}>
      <div className="news-item--image">
        <img
          alt={title}
          src={image?.meta.download_url || `${process.env.PUBLIC_URL}/images/placeholder-news.png`} />
      </div>

      <NewsItemInfo
        intro={intro}
        date={date}
        lang={lang} />
    </Link>
  );
};

export default NewsItem;