import React, { FC, useRef, useState } from 'react';

import Container from '../../layout/Container';
import Share from '../../assets/icons/share';
import NetworkShareContent from './NetworkShareContent';

import UseClickOutSide from '../../uses/useClickOutSide';

import { INetworkShare } from './interfaces';

import './styles.scss';

// network share
const NetworkShare: FC<INetworkShare> = ({
  type
}) => {
  const [ open, setOpen ] = useState<boolean>(false);

  const element: any = useRef<any>(null);

  // click out
  UseClickOutSide(element, () => setOpen(false));

  // render
  return (
    <div
      className="network-share"
      ref={element}
      data-type={type}>
      <Container type={2}>
        <div
          className="network-share--container"
          data-active={open}>
          <NetworkShareContent />
        </div>

        <button
          className="btn"
          onClick={() => setOpen(!open)}>
          <Share />
        </button>
      </Container>
    </div>
  );
};

export default NetworkShare;