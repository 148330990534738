import React from 'react';
import ReactDOM from 'react-dom';
import { ParallaxProvider } from 'react-scroll-parallax';

import reportWebVitals from './reportWebVitals';

import { BrowserRouter } from 'react-router-dom';
import { PortalProvider } from "react-portal-hook";

import MainProvider from './providers/MainProvider';
import NavProvider from './providers/NavProvider';
import LangProvider from './providers/LangProvider';

import Main from './layout/Main';

import './i18n';

import './styles/main.scss';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter basename="/">
      <ParallaxProvider>
        <PortalProvider>
          <LangProvider>
            <NavProvider>
              <MainProvider>
                <Main />
              </MainProvider>
            </NavProvider>
          </LangProvider>
        </PortalProvider>
      </ParallaxProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
