import React, { FC } from 'react';

import WebdoorControls from '../WebdoorControls';
import WebdoorInfoItem from './WebdoorInfoItem';

import { IWebdoorInfo } from './interfaces';

import './styles.scss';

// webdoor info
const WebdoorInfo: FC<IWebdoorInfo> = ({
  current,
  items,
  factoryLink,
  setCurrent
}) => {
  // render
  return (
    <div
      className="webdoor-info">
      <div className="webdoor-info--container">
        {items && items.map((item: any, index: number) =>
          <WebdoorInfoItem
            active={current === index}
            item={item}
            factoryLink={factoryLink}
            key={index} />)}
      </div>

      <WebdoorControls
        items={items}
        current={current}
        setCurrent={setCurrent} />
    </div>
  );
};

export default WebdoorInfo;